import {Button, Dropdown, TextArea} from "semantic-ui-react";
import messages from "./messages";
import {colors, fonts} from "../../../theme";
import {ReactComponent as CloseIcon} from "../../../assets/close.svg";
import globalMessages from "../../../libs/i18n/messages";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";

const DropdownItem = ({title}) => {
  return (
    <div style={{border: '1px solid', backgroundColor: colors.borderColorReallyDark}}>
      <span style={{...fonts.MEDIUM}}>{title}</span>
    </div>
  );
};

const ExtraAdjustmentPopupContent = (props) => {
  const {
    id,
    onClose,
    adjustments,
    selectedAdjustments: initialSelected,
    partialSelectedAdjustments: initialPartial,
  } = props;

  const [comment, setComment] = useState();
  const [partialSelected, setPartialSelected] = useState(initialPartial);
  const {formatMessage} = useIntl();
  const [selected, setSelected] = useState(initialSelected);

  useEffect(() => {
    if (id === 'multi') {
      setSelected([...initialSelected, ...initialPartial.map(i => i.id)]);
      setPartialSelected(initialPartial);
    }
  }, [initialSelected, initialPartial]);

  return (
    <div>
      <div>
        <Dropdown
          style={{width: '400px'}}
          multiple
          fluid
          selection
          placeholder={formatMessage({...messages.extra_adjustments})}
          closeOnChange={false}
          options={adjustments.sort((a, b) => a.name.localeCompare(b.name)).map(a => {
            return {key: a.id, text: a.name, value: a.id, content2: <DropdownItem title={a.name}/>};
          })}
          value={selected}
          onChange={(e, {value}) => {
            setSelected(value);
          }}
          renderLabel={(item) => {
            const partial = partialSelected.find(p => p.id === item.key) || false;
            return (
              <div
                style={{
                  backgroundColor: partial ? colors.borderColor : colors.darkGrey,
                  display: 'inline-block',
                  padding: '5px 10px 5px 10px',
                  margin: '2px',
                  flexDirection: 'row',
                  borderRadius: '2px',
                }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                      <span
                        style={{...fonts.MEDIUM, color: partial ? colors.textColor : colors.white}}>{item.text}</span>
                  {partial && <span style={{
                    ...fonts.SMALL,
                    backgroundColor: colors.darkGrey,
                    color: colors.white,
                    padding: '2px 6px 2px 6px',
                    borderRadius: '12px',
                    marginLeft: '4px'
                  }}>{partial.count}/{partial.total}</span>}
                  <CloseIcon
                    fill={partial ? colors.darkGrey : colors.white}
                    style={{
                      width: '8px',
                      height: '8px',
                      marginLeft: '12px',
                      cursor: 'pointer',
                      display: 'flex',
                      flexShrink: 0,
                      flexGrow: 0
                    }}
                    onClick={() => {
                      setSelected(selected.filter(s => s !== item.key));
                      setPartialSelected(partialSelected.filter(p => p.id !== item.key));
                    }}
                  />
                </div>
              </div>
            );
          }}
        />
        <div
          style={{
            paddingTop: '10px',
            marginTop: '10px',
            borderTop: `1px solid ${colors.borderColorDark}`,
          }}
        >
          <p style={fonts.MEDIUM}>{formatMessage({...messages.add_comment})}</p>
          <TextArea
            onChange={({target}) => setComment(target.value)}
            style={{minHeight: '100px', width: '100%'}}
          />
        </div>
      </div>
      <Button
        style={{marginTop: '5px'}}
        content={formatMessage({...globalMessages.ok})}
        onClick={() => {
          const changed = {};
          initialSelected.forEach(i => {
            if (!selected.find(s => s === i)) {
              changed[i] = false;
            }
          });
          selected.forEach(s => {
            if (!initialSelected.find(i => i === s) && !partialSelected.find(p => p.id === s)) {
              changed[s] = true;
            }
          });
          initialPartial.forEach(p => {
            if (!selected.find(s => s === p.id)) {
              changed[p.id] = false;
            }
          });
          onClose({changed, comment});
        }}
      />
      <Button
        style={{marginTop: '5px'}}
        content={formatMessage({...globalMessages.cancel})}
        onClick={() => onClose()}
      />
    </div>
  );
};
export default ExtraAdjustmentPopupContent;