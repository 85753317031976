import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useQuery, useMutation } from '@apollo/client';
import { gql } from 'apollo-boost'
// import { useIntl } from 'react-intl'
import PageLoader from '../../PageLoader'

const SCHOOL_ADMINS = gql`
    query($schoolId: String!) {
        schoolAdmins(schoolId: $schoolId) {
            id
        }
        schoolsAvailableAdmins(schoolId: $schoolId) {
            id
            first_name
            last_name
            email
        }
        userInfo {
            id
        }
    }
`

const UPDATE_SCHOOL_ADMINS = gql`
    mutation($schoolId: String!, $adminIds: [String!]) {
        updateSchoolAdmins(schoolId: $schoolId, adminIds: $adminIds)
    }
`

export default function SchoolsAdmin(props) {
    // const [syncGSuite, syncGSuiteData] = useMutation(SYNC_G_SUITE)
    // const [syncGCGuardians, syncGCGuardiansData] = useMutation(SYNC_G_C_GUARDIANS)
    const { schoolId } = props
    // const { formatMessage } = useIntl()

    const SCHOOL_ADMINS_CONF = {
        variables: { schoolId },
    }
    const { loading: adminsLoading, error: adminsError, data = {} } = useQuery(
        SCHOOL_ADMINS,
        SCHOOL_ADMINS_CONF,
    )
    const [updateSchoolAdmins, { loading: updateLoading, error: updateError }] = useMutation(
        UPDATE_SCHOOL_ADMINS,
        {
            refetchQueries: [{ ...SCHOOL_ADMINS_CONF, query: SCHOOL_ADMINS }],
        },
    )
    const { schoolAdmins = [], schoolsAvailableAdmins = [], userInfo = {} } = data

    if (!schoolId) {
        return 'Select School'
    }

    if (adminsLoading) {
        return <PageLoader />
    }
    if (adminsError || updateError) {
        return 'error'
    }

    return (
        <div>
            <Dropdown
                loading={adminsLoading || updateLoading}
                placeholder="Admins"
                fluid
                multiple
                selection
                search
                options={schoolsAvailableAdmins
                    .filter(a => a.id !== userInfo.id)
                    .map(admin => ({
                        key: admin.id.toString(),
                        text: `${admin.first_name} ${admin.last_name}`,
                        value: admin.id.toString(),
                    }))}
                value={schoolAdmins.map(admin => admin.id.toString())}
                onChange={(e, data) => {
                    updateSchoolAdmins({
                        variables: {
                            schoolId,
                            adminIds: data.value,
                        },
                    })
                }}
            />
        </div>
    )
}
