import React, {useEffect, useState} from 'react';
import {gql, useLazyQuery} from "@apollo/client";
import {Button, Dropdown, Label, Popup, Tab} from "semantic-ui-react";
import messages from "../EWSFilter/messages";
import {useHistory} from "react-router-dom";
import {useIntl} from "react-intl";
import {useParams} from "../../../libs/hooks";
import {colors, fonts} from "../../../theme";

const EWS_FILTER_QUERY = gql`
  query($schoolId: Int!) {
    ewsFilter(schoolId: $schoolId)
  }
`;

const EWSFilter = ({}) => {
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedClassrooms, setSelectedClassrooms] = useState([]);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const {formatMessage} = useIntl();
  const params = useParams();
  const schoolId = parseInt(params?.schoolId);
  const studentIds = params?.studentIds;
  const classroomIds = params?.ids;

  const [fetch, {loading, error, data}] = useLazyQuery(EWS_FILTER_QUERY, {fetchPolicy: 'cache-and-network'});


  useEffect(() => {
    if (schoolId) {
      fetch({
        variables: {
          schoolId,
        }
      })
    }
  }, [schoolId]);

  useEffect(() => {
    let selectedSt = [];
    let selectedCl = [];
    if (history.location.pathname.includes('classes')) {
      selectedCl = classroomIds?.split(',')
    } else {
      selectedSt = studentIds?.split(',');
    }
    setSelectedStudents(selectedSt);
    setSelectedClassrooms(selectedCl);
  }, [studentIds, classroomIds]);

  const activeIndex = history.location.pathname.includes('classes') ? 1 : 0;

  const studentLists = data?.ewsFilter?.studentLists?.map(l => ({...l, id: `list_${l.id}`})) || [];
  const students = data?.ewsFilter?.students || [];

  const classroomOptions = data?.ewsFilter?.classrooms?.map(c => {
    const roomSection = [c.room, c.section].filter(rs => rs !== '');
    return ({
      key: c.id,
      value: c.id,
      text: `${c.name} ${roomSection.map(rs => ` ,${rs}`)}`,
      description: c.archived ? formatMessage({...messages.archived}) : 'Google Classroom',
    });
  }) || [];

  const studentAndListOptions = [...studentLists, ...students]?.map(s => {
    const isList = s?.source_id;
    let description = isList ? formatMessage({...messages.studentList}) : '';
    if (s.mentorStudent) {
      description = formatMessage({...messages.mentorStudent});
    }
    return ({
      key: s.id.toString(),
      value: s.id.toString(),
      text: isList ? s.name : `${s.first_name} ${s.last_name}`,
      description: description,
    });
  });

  studentAndListOptions.unshift({key: 1, value:'mentor', text: formatMessage({...messages.mentorGroup}), description: formatMessage({...messages.studentList})});

  const changeStudentFilter = () => {
    const prefix = '/ews';
    if (selectedStudents.length > 0) {
      history.push(`${prefix}/${schoolId}/students/${selectedStudents.join(',')}`);
    } else if (schoolId) {
      history.push(`${prefix}/${schoolId}`);
    } else {
      history.push(prefix);
    }
  };

  const changeClassroomFilter = () => {
    const prefix = '/ews';
    if (selectedClassrooms.length > 0) {
      history.push(`${prefix}/${schoolId}/classes/${selectedClassrooms.join(',')}`);
    } else if (schoolId) {
      history.push(`${prefix}/${schoolId}`);
    } else {
      history.push(prefix);
    }
  };

  const allIds = [studentIds, classroomIds].join(',').split(',').filter(id => !(id === ''));
  let triggerContent = allIds.length ? allIds?.map(s => {
    let text = [...studentAndListOptions, ...classroomOptions]?.find(o => o.value.toString() === s)?.text;
    return (<Label>{text}</Label>);
  }) : <span style={fonts.MEDIUM}>{formatMessage({...messages.placeholder})}</span>;


  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <span style={{...fonts.MEDIUM, marginRight: '6px'}}> {formatMessage({...messages.label})}</span>
      <Popup
        flowing
        eventsEnabled
        trigger={<div
          style={{
            height: '38px',
            border: `1px solid ${colors.borderColorDark}`,
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            padding: '0px 12px 0px 12px'
          }}
          onClick={() => setOpen(true)}>{triggerContent}</div>}
        open={open}
        on={['click']}
        content={
          <Tab defaultActiveIndex={activeIndex} panes={[
            {
              menuItem: formatMessage({...messages.studentsTab}), render: () => (
                <Tab.Pane>
                  <Dropdown
                    placeholder={formatMessage({...messages.studentPlaceholder})}
                    options={studentAndListOptions}
                    deburr
                    multiple
                    selection
                    search
                    clearable
                    closeOnChange
                    value={selectedStudents||[]}
                    onChange={(e, data) => {
                      setSelectedStudents(data.value);
                    }}
                    style={{
                      width: '50vw',
                    }}
                  />
                  <Button
                    color="green"
                    onClick={() => {
                      setOpen(false);
                      changeStudentFilter();
                    }}
                    style={{marginTop: 20}}
                  >
                    {formatMessage({...messages.confirm})}
                  </Button>
                </Tab.Pane>)
            },
            {
              menuItem: formatMessage({...messages.classroomsTab}), render: () => (
                <Tab.Pane>
                  <Dropdown
                    placeholder={formatMessage({...messages.classroomPlaceholder})}
                    options={classroomOptions}
                    deburr
                    multiple
                    selection
                    search
                    clearable
                    closeOnChange
                    value={selectedClassrooms||[]}
                    onChange={(e, data) => {
                      setSelectedClassrooms(data.value);
                    }}
                    style={{
                      width: '50vw',
                    }}
                  />
                  <Button
                    color="green"
                    onClick={() => {
                      setOpen(false);
                      changeClassroomFilter();
                    }}
                    style={{marginTop: 20}}
                  >
                    {formatMessage({...messages.confirm})}
                  </Button>
                </Tab.Pane>)
            },
          ]}/>
        }
        onClose={() => {
          setOpen(false);
        }}
        position="top left"
      />
    </div>
  );
};

export default EWSFilter;