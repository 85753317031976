import { defineMessages } from 'react-intl'

export default defineMessages({
    label: `Filter:`,
    header: `Filter`,
    subheader: `You can filter by classrooms or students`,
    noAdmin: 'You are not admin of this school',
    confirm: 'Confirm',
    studentPlaceholder: 'Select students',
    classroomPlaceholder: 'Select classroom',
    placeholder: 'classrooms or students',
    classroomsTab: 'Classrooms',
    studentsTab: 'Students',
    archived: 'Archived',
    studentList: 'Student list',
    mentorGroup: 'My mentor group',
    mentorStudent: 'Mentor student'
})
