import { GoogleLogin } from 'react-google-login';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';


const scopes = [
  // 'https://www.googleapis.com/auth/classroom.rosters',
  'https://www.googleapis.com/auth/classroom.rosters.readonly',
  'https://www.googleapis.com/auth/classroom.profile.emails',
  // 'https://www.googleapis.com/auth/classroom.profile.photos',
  // 'https://www.googleapis.com/auth/admin.directory.group.readonly',
  // 'https://www.googleapis.com/auth/classroom.courses',
].join(' ');

const GoogleLoginButton = (props) => {
  const { formatMessage } = useIntl();
  const { response } = props;
  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_CLIENT_ID}
      scope={scopes}
      buttonText={formatMessage({ ...messages.signinGoogle })}
      onSuccess={response}
      onFailure={response}
      cookiePolicy={'single_host_origin'}
      responseType={'code'}
      // prompt={'consent'}
    />
  );
};

export default GoogleLoginButton;
