import { Button, Dropdown } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { fonts } from '../../theme';
import request from '../../libs/request';
import messages from './messages';

const SelectCountry = (props) => {
  const { onDone } = props;
  const { formatMessage } = useIntl();
  const [country, setCountry] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getSchools = async () => {
      const countries = (await request.get('/v3/schools/countries/')).data;
      setOptions(countries);
    };
    getSchools();
  }, []);

  return (
    <>
      <div>
        <p style={{ ...fonts.LARGE, textAlign: 'center' }}>{formatMessage({ ...messages.welcome_title })}</p>
        <span style={{ ...fonts.MEDIUM }}>{formatMessage({ ...messages.welcome_message })}</span>
      </div>
      <Dropdown
        style={{ marginTop: '20px' }}
        fluid
        placeholder={formatMessage({ ...messages.choose_country })}
        selection
        options={options.map((c) => ({ key: c.country_code, value: c.country_code, text: c.name }))}
        onChange={(e, data) => setCountry(data.value)}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ ...fonts.TINY }}>{formatMessage({ ...messages.country_select_hint })}</span>
        <Button onClick={() => onDone(country)} color={'yellow'} style={{ marginTop: '60px' }} disabled={!country}>
          {formatMessage({ ...messages.continue })}
        </Button>
      </div>
    </>
  );
};

export default SelectCountry;
