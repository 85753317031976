import { defineMessages } from 'react-intl';

export default defineMessages({
  student_progress: `Student progress`,
  course_description: 'Course description',
  overall_assessment: 'Overall assessment',
  modified: 'Modified',
  assessment: 'Assessment',
  target_grade: 'Target grade',
  feedback: 'Feedback',
  assignment: 'Assignment',
  current_assessment: 'Current assessment',
  assignments: 'Assignments',
  name: 'Name',
  status: 'Status',
  print: 'Print PDF'
});
