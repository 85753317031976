import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import {IntlProvider} from 'react-intl';
import {connect, useDispatch, useSelector} from 'react-redux';
import {
  HashRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {fonts, colors} from '../../theme';
import {logout} from '../../redux/reducers/auth';
import Header from '../Header';
import Login from '../Login';
import EWS from '../EWS';
import SyncData from '../SyncData';
import SchoolsAdmin from '../SchoolsAdmin';
import authLib from '../../libs/auth';
import {messages} from '../../libs/i18n';
import './style.scss';
import TokenExpireChecker, {TOKEN_EXPIRE_BLOCK_UI_THRESHOLD, TOKEN_EXPIRE_GRACE_PERIOD} from './tokenExpireChecker';
import LoginSplash from '../Login/loginSplash';
import Setup from '../Setup';
import {setError, setUpdating} from "../../redux/reducers/app";
import UpdatingInfo from "./updatingInfo";

const PrivateRoute = ({component: Component, ...rest}) => {
  const {
    location: {pathname: from},
  } = rest;

  const redirectPropTo = {
    pathname: '/login',
    state: {from},
  };

  return (
    <Route
      {...rest}
      render={(props) => (rest.auth.user.id ? (
        <div style={{padding: 10}}>
          <Component {...{...props, ...rest}} />
        </div>
      ) : (
        <Redirect to={redirectPropTo}/>
      ))
      }
    />
  );
};


export const routes = {
  login: "/login",
  setup: "/login/setup",
  root: "/",
  ews: "/ews",
  loOverview: "/ews/loOverview/:classroomId",
  syncData: "/syncData",
  schoolAdmin: "/schoolsAdmin",
  schoolAdmin_schoolId: "/schoolsAdmin/:schoolId",
  schoolAdmin_subpage: "/schoolsAdmin/:schoolId/:subpage",
  ews_schoolId: "/ews/:schoolId",
  ews_classIds: "/ews/:schoolId/classes/:ids",
  ews_studentIds: "/ews/:schoolId/students/:studentIds",
  ews_groupIds: "/ews/:schoolId/:classGroupId",
  ews_classess: "/ews/:schoolId/:classGroupId/classes/:ids",
};

function App(props) {
  const language = navigator.language.split(/[-_]/)[0];
  const timeLeftToExpire = useSelector((state) => state.auth.timeLeftToExpire);
  const {auth} = props;
  const dispatch = useDispatch();
  const error = useSelector(state => state.app.error);

  const blockUi = timeLeftToExpire > 0 && timeLeftToExpire <= TOKEN_EXPIRE_BLOCK_UI_THRESHOLD;
  const showLoginSplash = timeLeftToExpire > 0 && timeLeftToExpire <= TOKEN_EXPIRE_GRACE_PERIOD;

  const emailLogin = auth?.user?.emailLogin;
  return (
    <IntlProvider locale={language} key={language} messages={messages[language]}>
      {!emailLogin &&
      <TokenExpireChecker/>
      }
      {showLoginSplash && (
        <LoginSplash blockUi={blockUi}/>
      )}
      {blockUi && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: colors.dimmed,
            zIndex: 10000,
          }}
        />
      )}
      <div id={blockUi ? 'blur' : ''}>
        <Router>
          <div>
            {auth?.user?.id && <Header {...props} />}
            <Switch>
              <Route exact path={routes.login} component={Login}/>
              <Route exact path={routes.setup} component={Setup}/>
              <PrivateRoute exact path={routes.root} component={EWS} {...props} />
              <PrivateRoute exact path={routes.ews} component={EWS} {...props} />
              <PrivateRoute exact path={routes.loOverview} component={EWS} {...props} />
              <PrivateRoute exact path={routes.syncData} component={SyncData} {...props} />
              <PrivateRoute exact path={routes.schoolAdmin} component={SchoolsAdmin} {...props} />
              <PrivateRoute exact path={routes.schoolAdmin_schoolId} component={SchoolsAdmin} {...props}/>
              <PrivateRoute exact path={routes.schoolAdmin_subpage} component={SchoolsAdmin} {...props}/>
              <PrivateRoute exact path={routes.ews_schoolId} component={EWS} {...props} />
              <PrivateRoute exact path={routes.ews_classIds} component={EWS} {...props}/>
              <PrivateRoute exact path={routes.ews_studentIds} component={EWS} {...props} />
              <PrivateRoute exact path={routes.ews_groupIds} component={EWS} {...props} />
              <PrivateRoute exact path={routes.ews_classess} component={EWS} {...props} />
            </Switch>
          </div>
        </Router>
        {error && (
          <div style={style.dimContainer}>
            <div style={style.errorDialog}>
              <p style={fonts.LARGE}>{error.title}</p>
              <p style={fonts.MEDIUM}>{error.message}</p>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button onClick={() => dispatch(setError(null))} style={{}}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <UpdatingInfo/>
    </IntlProvider>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    authLib.logout();
    dispatch(logout());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(App);

const style = {
  dimContainer: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#00000080',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10000,
  },
  errorDialog: {
    position: 'absolute',
    border: '1px solid grey',
    minWidth: '150px',
    minHeight: '100px',
    maxWidth: ' 300px',
    backgroundColor: colors.white,
    borderRadius: '5px',
    padding: '10px',
    zIndex: 10000,
  },
};
