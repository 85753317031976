import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { gql } from 'apollo-boost';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Message } from 'semantic-ui-react';
import authLib from '../../libs/auth';
import * as authActions from '../../redux/reducers/auth';
import bgImage from '../../assets/login_bg.png';
import devImage from '../../assets/dev_site.png';
import StudybeeGoogleLogin from './studybeeGoogleLogin';
import PageLoader from '../PageLoader';
import StudybeeEmailLogin from './studybeeEmailLogin';
import messages from './messages';
import { colors } from '../../theme';
import Setup from '../Setup';


const USER_INFO = gql`
  {
    userInfo {
      id
      email
      first_name
      last_name
      is_teacher
      is_admin
      superAdminSchoolIds
      adminSchoolIds
    }
  }
`;

const GET_ACCESS_TOKEN = gql`
    query($code: String!) {
      getAccessToken(code: $code)
    }
`;

const Login = (props) => {
  const {
    location: { state = {} },
    login,
    logout,
    auth,
    auth: {
      user: { wasLoggedOut },
    },
  } = props;

  const { from: redirect = '/' } = state;
  const [loading, setLoading] = useState(false);
  const [emailLogin, setEmailLogin] = useState(false);
  const [loginError, setLoginError] = useState();
  const [showSetup, setShowSetup] = useState(false);
  const { formatMessage } = useIntl();
  const PROD = process.env.REACT_APP_PROD === 'true';

  const [getUserInfo] = useLazyQuery(USER_INFO, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.userInfo) {
        const { userInfo } = data;
        const { adminSchoolIds: adminSchools, is_teacher: isTeacher } = userInfo;
        setLoading(false);
        if (isTeacher) {
          const user = {
            ...userInfo,
            isTeacher,
            adminSchools,
            emailLogin,
          };

          authLib.storeUser({ user });
          login({ user });
        } else {
          setLoginError(formatMessage({ ...messages.not_verified_teacher_error }));
        }
      } else {
        logout();
      }
    },
    onError: async ({ networkError }) => {
      if (networkError.result.errors) {
        const missingUser = networkError.result.errors.find((e) => e.message === 'AUTHORIZATION__NO_USER');
        if (missingUser) {
          // route to setup
          setShowSetup(true);
          return;
        }
      }
      setLoginError(formatMessage({ ...messages.loginError }));
    },
  });

  const [getAccessToken] = useLazyQuery(GET_ACCESS_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getAccessToken: accessToken }) => {
      authLib.storeToken({
        accessToken,
      });
      getUserInfo();
    },
    onError: (e) => {
      console.log('getAccessToken error: ', e);
    },
  });

  useEffect(() => {
    setLoginError(false);
  }, [emailLogin]);

  if (auth.user.id) {
    return <Redirect to={wasLoggedOut ? '/' : redirect} />;
  }

  if (loading) {
    return <PageLoader />;
  }

  const translateError= (error) => {
    switch (error){
      case 'popup_closed_by_user':
        return formatMessage({...messages.loginErrorCookies})
    }
  };

  const loginWithToken = (token) => {
    authLib.storeEmailToken({ token });
    getUserInfo();
  };

  const loginWithCode = (response) => {
    if(response.error){
      setLoginError(translateError(response.error));
      return;
    }
    getAccessToken({
      variables: {
        code: response.code,
      },
    });
  };
  return (
    <div>
      <img src={bgImage} style={{
        position: 'fixed', backgroundSize: 'cover', width: '100%', height: '100%',
      }} />
      {!PROD
      && <div style={{
        position: 'absolute',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        backgroundColor: colors.red,
        opacity: 0.5,
        display: 'flex',
        justifyContent: 'center',
      }} />
      }
      <div
        style={{
          position: 'absolute',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {!PROD
        && <img src={devImage} style={{ width: '40%', marginBottom: '20px' }} />
        }
        {showSetup && <Setup/>}
        {(!showSetup && !emailLogin) && <StudybeeGoogleLogin login={loginWithCode} emailLogin={() => setEmailLogin(true)} />}
        {(!showSetup && emailLogin)
        && <StudybeeEmailLogin login={loginWithToken} back={() => setEmailLogin(false)} onError={setLoginError} />}
        {(loginError) && <Message error visible>
          <Message.Header>{formatMessage({ ...messages.loginErrorTitle })}</Message.Header>
          <p>{loginError}</p>
        </Message>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  login: ({ user }) => {
    dispatch(
      authActions.login({
        user,
      }),
    );
  },
  logout: () => {
    authLib.logout();
    dispatch(authActions.logout());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
