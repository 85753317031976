import { defineMessages } from 'react-intl';

export default defineMessages({
  noSelectedFilter: 'Select classroom or student',
  noStudents: 'Choose a different filter, there are no students with this filter.',
  noCourses: 'There are no courses',
  selected: 'Selected',
  teacher_status: 'Set teacher status',
  set_extra_adjustments: 'Set extra adjustments',
  accept_suggested_status: 'Accept suggested status',
  latest_teacher_status: 'Current teacher status',
  suggested_status: 'Suggested status',
  grade: 'Grade',
  extra_adjustments: 'Extra adjustments',
  students: 'Students',
  suggested_status_tip_text: 'Suggested status is based on the grades entered for assignments, objectives and courses',
  read_more: 'Read more',
  change_many_warning: 'Are you sure, this will edit many objects?',
  change_selected_objects: 'Change selected objects',
  error: 'Error',
  error_save_filter: 'Could not save filter. Try again, if the error persists contact StudyBee support.',
  error_save_status: 'Could not save status. Try again, if the error persists contact StudyBee support.',
  error_save_adjustments: 'Could not save learning adjustments. Try again, if the error persists contact StudyBee support.',
  all_courses_hidden: 'All courses in this filter has been hidden, to view them, click ',
  here: 'here',
  teachers: 'Teachers',
  select_school: 'Select school',
  loading: 'Loading',
  splash_url: 'https://docs.google.com/document/d/e/2PACX-1vRYB27Z88gshnS6GWf8PvvNjBZ-ugRjOA1qLA22EjtUAIm5n-8VqGx9g58jzIKgmPfCwONyLZhCk7Em/pub?embedded=true',
});
