import authLib from '../../libs/auth';

const PREFIX = 'AUTH';

export const AUTH_UPDATE_USER = `${PREFIX}_UPDATE_USER`;
export const AUTH_TIME_LEFT_TO_EXPIRE = `${PREFIX}_TIME_LEFT_TO_EXPIRE`;
export const AUTH_UPDATE_ACCESS_TOKEN = `${PREFIX}_UPDATE_ACCESS_TOKEN`;

export const updateTimeLeftToExpire = (timeLeft) => ({
  type: AUTH_TIME_LEFT_TO_EXPIRE,
  payload: timeLeft,
});

export const updateAccessToken = (accessToken) => ({
  type: AUTH_UPDATE_ACCESS_TOKEN,
  payload: accessToken,
});

export const login = (payload = {}) => ({
  type: AUTH_UPDATE_USER,
  payload: { ...payload },
});

export const logout = () => {
  authLib.logout();
  return {
    type: AUTH_UPDATE_USER,
    payload: {
      user: {},
      tokenInfo: null,
      timeLeftToExpire: 0,
    },
  };
};

export const updateAuthUser = (payload = {}) => ({
  type: AUTH_UPDATE_USER,
  payload,
});

const INITIAL_STATE = {
  user: authLib.getUser() || {},
  timeLeftToExpire: 0,
  accessToken: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_UPDATE_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    case AUTH_TIME_LEFT_TO_EXPIRE:
      return { ...state, timeLeftToExpire: action.payload };
    case AUTH_UPDATE_USER:
      return {
        ...state,
        user: {
          ...action.payload.user,
        },
      };
    default:
      return state;
  }
}
