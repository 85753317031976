import React from 'react'
import ReactModal from 'react-modal'
import './style.scss'

ReactModal.setAppElement('#root')

export default function Modal(props) {
    const {
        isOpen,
        onAfterOpen,
        onRequestClose,
        shouldCloseOnOverlayClick,
        children,
        contentClass,
        size = 'none', // size options [large, medium, small]
    } = props
    const className = `modal__main-content modal--${size} ${contentClass || ''}`
    return (
        <ReactModal
            portalClassName='modal-portal'
            className={{
                base: 'modal',
                afterOpen: 'modal--open',
                beforeClose: 'modal--before-close',
            }}
            overlayClassName={{
                base: 'cover',
                afterOpen: 'cover--open',
                beforeClose: 'cover--before-close',
            }}
            closeTimeoutMS={300}
            isOpen={isOpen}
            onAfterOpen={onAfterOpen}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            onAfterClose={() => {
                document.body.className = document.body.className.replace(
                    'ReactModal__Body--open',
                    '',
                )
            }}
        >
            <div className={className}>{children}</div>
        </ReactModal>
    )
}

export function ModalHeader(props) {
    const { children } = props
    return <div className='modal__header'>{children}</div>
}

export function ModalContent(props) {
    const { children } = props
    return <div className={`modal__content`}>{children}</div>
}

export function ModalFooter(props) {
    const { children, error } = props
    return (
        <div className='modal__footer'>
            <div className='modal__footer-left'>
                {error && (
                    <i
                        className='fa fa-exclamation-circle'
                        style={{ color: '#de5754' }}
                    />
                )}
            </div>
            <div className='modal__footer-right'>{children}</div>
        </div>
    )
}

