import React from 'react';
import {
  Card, Checkbox, Popup, PopupContent,
} from 'semantic-ui-react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {colors, fonts} from '../../../theme';
import expandIcon from '../../../assets/expand.svg';
import hideIcon from '../../../assets/hide.svg';
import hiddenIcon from '../../../assets/hidden.svg';
import information from '../../../assets/information.png';
import messages from './messages';
import detailsIcon from '../../../assets/view-details.svg';
import archiveIcon from '../../../assets/archived.svg';

export default function StudentCourseHeaderExpanded(props) {
  const {
    expanded,
    setExpanded,
    course,
    selectMode,
    setSelected,
    isSelected,
    highlight,
    toggleHide,
    schoolId,
    teachers,
    isHidden: filterHidden = false,
  } = props;
  const {name, classroomId, classroomName, archived} = course;
  const {formatMessage} = useIntl();
  const color = filterHidden ? colors.borderColorDark : colors.textColor;

  if (!expanded) {
    return null;
  }

  return (
    <div
      style={{...style.container, backgroundColor: highlight ? colors.inactive : colors.white}}
    >
      <div style={style.verticalTextContainer}>
        <p style={{...fonts.TINY, color}}>
          {formatMessage({...messages.latest_teacher_status})}
        </p>
        <p style={{...fonts.TINY, color}}>{formatMessage({...messages.suggested_status})}</p>
        <p style={{...fonts.TINY, color}}>{formatMessage({...messages.grade})}</p>
        <p style={{...fonts.TINY, color}}>{formatMessage({...messages.extra_adjustments})}</p>
      </div>
      <div
        style={{
          ...style.courseNameContainer,
          transform: `rotate(90deg) translate(-100px, -${selectMode ? 80 : 100}px)`,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {archived && <img src={archiveIcon} style={{width: '24px', height: '24px', position: 'absolute', top: '6px'}}/>}
        <div>
          <p
            style={{
              ...style.truncatedText,
              ...fonts.SMALL,
              fontWeight: '800',
              lineHeight: '12px',
              color,
              marginLeft: archived ? '30px' : '5px',
              width: archived ? '120px' : '150px',
            }}
          >
            {name}
          </p>
          <NavLink to={`/ews/${schoolId}/classes/${classroomId}`}>
            <p
              style={{
                ...style.truncatedText,
                ...fonts.SMALL,
                lineHeight: '12px',
                color,
                marginLeft: archived ? '30px' : '5px',
                width: archived ? '120px' : '150px',
              }}
            >
              {classroomName}
            </p>
          </NavLink>
        </div>
        {/*<NavLink to={`/ews/${schoolId}/classes/${classroomId}`}>*/}
        {process.env.REACT_APP_LO_OVERVIEW_ENABLED === 'true'
        && <NavLink to={`/ews/loOverview/${classroomId}`}>
          <img
            src={detailsIcon}
            style={{
              width: '16px',
              height: '16px',
              position: 'absolute',
              right: '10px',
              top: '25px',
              cursor: 'pointer',
            }}
          />
        </NavLink>
        }
        <Popup
          on={'click'}
          basic
          pinned
          trigger={
            <div>
              <img
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '6px',
                }}
                alt="T"
                src={information}
              />
            </div>
          }
        >
          <PopupContent>
            <Card style={{maxWidth: '400px'}}>
              <Card.Content>
                <Card.Header>{course.name}</Card.Header>
                <Card.Meta style={{marginBottom: '10px'}}>{course.code || 'no code'}</Card.Meta>
                <p style={{...fonts.MEDIUM, fontWeight: '800'}}>
                  {formatMessage({...messages.teachers})}
                </p>
                {teachers.map((t) => (
                  <p style={{...fonts.MEDIUM}}>
                    {t.first_name} {t.last_name}
                  </p>
                ))}
              </Card.Content>
            </Card>
          </PopupContent>
        </Popup>
      </div>
      <div style={{...style.iconContainer, width: selectMode ? '60px' : '41px'}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div onClick={() => setExpanded(!expanded)}>
            <img alt={'->'} src={expandIcon} style={{transform: 'rotate(-90deg)'}}/>
          </div>
          <div>
            <div onClick={() => toggleHide(course)}>
              <img
                alt={'X'}
                src={filterHidden ? hiddenIcon : hideIcon}
                style={{
                  marginBottom: '12px',
                  width: '16px',
                  height: '16px',
                  transform: 'rotate(90deg)',
                }}
              />
            </div>
          </div>
        </div>
        {selectMode && (
          <Checkbox
            checked={isSelected}
            style={{marginLeft: '10px', transform: 'rotate(90deg)'}}
            onChange={setSelected}
          />
        )}
      </div>
      {archived && <div style={{
        backgroundColor: colors.greyOpacity,
        position: 'absolute',
        width: '210px',
        height: '190px',
        border: '0px solid',
        top: 0,
        left: 0
      }}/>}
    </div>
  );
}

const style = {
  container: {
    width: '250px',
    height: '190px',
    borderBottom: `1px solid ${colors.borderColor}`,
    position: 'relative',
    paddingLeft: '5px',
    backgroundColor: colors.white,
  },
  courseNameContainer: {
    display: 'flex',
    transformOrigin: '0% 100%',
    position: 'absolute',
    padding: '5px 5px 5px 5px',
    height: '100px',
    width: '190px',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  iconContainer: {
    cursor: 'pointer',
    border: '1px solid #F6F6F6',
    display: 'flex',
    width: '41px',
    height: '190px',
    justifyContent: 'center',
    padding: '10px 0px 0px 0px',
    alignItems: 'flex-start',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
  },
  verticalTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: `${38 * 4}px`,
    width: '130px',
    justifyContent: 'space-around',
    left: '5px',
    bottom: 0,
    position: 'absolute',
    padding: '2px 0px 2px 0px',
  },
  truncatedText: {
    width: '150px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: '0px 0px 0.2rem',
  },
};
