import extraAdjustmentIcon from '../../../../assets/extra-help.svg';
import { colors, fonts } from '../../../../theme';
import React from 'react';

export default function ExtraAdjustmentIcon(props) {
  const { extraAdjustments, small } = props;
  if(!extraAdjustments){
    return (
      <img
        alt={'X'}
        style={{ width: small ? '10px' : '20px', height: small ? '10px' : '20px', opacity:'0.2' }}
        src={extraAdjustmentIcon}
      />
    );
  }
  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      <img
        alt={'X'}
        style={{ width: small ? '10px' : '20px', height: small ? '10px' : '20px' }}
        src={extraAdjustmentIcon}
      />
      <div style={{ ...style.iconContainer, height: small?'6px':'14px', width: small?'6px':'14px', fontWeight: '500' }}>
        <p style={{ ...fonts.TINY, color: colors.white, fontSize: small?'6px':'12px', marginLeft: small?'0px':'1px' }}>{extraAdjustments}</p>
      </div>
    </div>
  );
}

const style = {
  iconContainer: {
    border: '1px solid black',
    backgroundColor: colors.black,
    borderRadius: '20px',
    position: 'absolute',
    bottom: '-1px',
    right: '-1px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};
