import React from 'react';
import {colors} from '../../../../theme';
import ExtraAdjustmentIcon from './extraAdjustmentIcon';
import {ExtraAdjustmentPopup} from '../../QuickActions';
import {useMutation} from "@apollo/client";
import {ADD_EWS_COMMENT, UPDATE_EXTRA_ADJUSTMENTS} from "../../../../client";
import {useDispatch} from "react-redux";
import {setUpdating} from "../../../../redux/reducers/app";

export default function ExtraAdjustmentStatus(
  {
    availableAdjustments,
    extraAdjustments,
    courseId,
    studentId,
    onSave
  }) {
  const [addEwsComment] = useMutation(ADD_EWS_COMMENT);
  const [updateExtraAdjustments] = useMutation(UPDATE_EXTRA_ADJUSTMENTS);
  const dispatch = useDispatch();

  const save = async ({changed, comment}) => {
    dispatch(setUpdating(true));
    if (comment) {
      await addEwsComment({
        variables: {
          comment,
          courseId,
          studentId,
        }
      });
    }
    let newAdjustments = [...extraAdjustments];
    Object.keys(changed).forEach(seId => {
      const eId = parseInt(seId);
      if (changed[eId] && !newAdjustments.find(c => c === eId)) {
        newAdjustments.push(eId)
      } else if (!changed[eId]) {
        newAdjustments = newAdjustments.filter(c => c !== eId);
      }
    });

    await updateExtraAdjustments({
      variables: {
        adjustments: newAdjustments,
        courseId,
        studentId,
      }
    });

    await onSave();
  };
  return (
    <div
      style={{
        width: '38px',
        height: '47px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${colors.borderColorDark}`,
        borderRight: `1px solid #000`,
      }}
    >
      <ExtraAdjustmentPopup
        id={`${courseId}${studentId}`}
        adjustments={availableAdjustments}
        selectedAdjustments={extraAdjustments}
        partialSelectedAdjustments={[]}
        basic={true}
        onClose={save}
        trigger={
          <div style={{cursor: 'pointer'}}>
            <ExtraAdjustmentIcon {...{extraAdjustments: extraAdjustments.length}} />
          </div>
        }
      />
    </div>
  );
}
