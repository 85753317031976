import React from 'react';
import { useIntl } from 'react-intl';
import { colors, fonts } from '../../theme';
import messages from './messages';
import GoogleLoginButton from './googleLoginButton';
import StudyBeeLogo from '../../assets/studybee_logo.png';


const StudybeeGoogleLogin = (props) => {
  const { login, emailLogin } = props;
  const { formatMessage } = useIntl();

  const responseGoogle = async (response) => {
    console.log('responseGoogle: ', response);
    try {
      login(response);
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '450px',
          alignItems: 'center',
          flexDirection: 'column',
          border: `1px solid ${colors.borderColor}`,
          backgroundColor: colors.white,
          borderRadius: '5px',
          boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
          paddingTop: '20px',
        }}
      >
        <img
          src={StudyBeeLogo}
          style={{ width: '250px' }}
        />
        <div
          style={{
            width: '100%',
            borderTop: `0px solid ${colors.borderColor}`,
            padding: '0px 50px 20px 50px',
            textAlign: 'center',
          }}
        >
          <p style={fonts.MEDIUM}>{formatMessage({ ...messages.login_welcome })}</p>
        </div>
        <GoogleLoginButton response={responseGoogle}/>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: colors.borderColor,
            margin: '20px 0 0 0',
            padding: '20px',
            alignItems: 'center',
          }}
        >
          <span style={fonts.MEDIUM}>{formatMessage({ ...messages.no_google_account })}</span>
          <span
            style={{
              ...fonts.MEDIUM, color: colors.blue, marginLeft: '0px', cursor: 'pointer',
            }}
            onClick={emailLogin}
          >
            {formatMessage({ ...messages.click_for_email_login })}
          </span>
        </div>
      </div>
      <p style={{ ...fonts.MEDIUM, padding: '20px 20px 20px 20px' }}>
        {formatMessage({ ...messages.read_more })}
        <a style={{ ...fonts.MEDIUM, color: colors.blue }} href={'https://studybee.io'}>
          studybee.io
        </a>
      </p>
    </div>
  );
};

export default StudybeeGoogleLogin;
