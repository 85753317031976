import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Dropdown, Image } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { gql } from 'apollo-boost';
import messages from './messages';
import StudyBeeLogo from '../../assets/studybee_logo.png';

const USER_INFO = gql`
  {
    userInfo {
      id
      first_name
      last_name
      email
      is_teacher
      google_id
      photo_url
      is_admin
      superAdminSchoolIds
      adminSchoolIds
    }
  }
`;
export default function Header(props) {
  const { formatMessage } = useIntl();
  const { loading /* , error */, data } = useQuery(USER_INFO, {
    fetchPolicy: 'network-only',
  });
  if (!props.auth.user.id) {
    return null;
  }
  if (loading || !data) {
    return null;
  }
  const { userInfo: user } = data;
  if (!user.id) {
    return null;
  }
  return (
    <Menu
      stackable
      secondary
      style={{
        background: '#FBCD03',
        color: '#000',
        marginBottom: 0,
        paddingLeft: '20px',
        paddingRight: '20px',
      }}
    >
      <NavLink to="/">
        <div
          style={{
            width: 153,
            height: 62,
            overflow: 'hidden',
          }}
        >
          <img
            alt="logo"
            src={StudyBeeLogo}
            style={{ height: 57 }}
          />
        </div>
      </NavLink>

      <Menu.Item as={NavLink} to="/ews">
        {formatMessage({ ...messages.pageEWS })}
      </Menu.Item>

      {user.is_admin ? (
        <Menu.Item as={NavLink} to="/syncData">
          {formatMessage({ ...messages.pageSync })}
        </Menu.Item>
      ) : null}

      {user.superAdminSchoolIds?.length || user.adminSchoolIds?.length ? (
        <Menu.Item as={NavLink} to="/schoolsAdmin">
          {formatMessage({ ...messages.pageSchoolsAdmin })}
        </Menu.Item>
      ) : null}
      <Menu.Menu position="right">
        <Dropdown
          item
          trigger={
            <span>
              <Image avatar src={user.photo_url} /> {user.first_name} {user.last_name}
            </span>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={props.logout}>
              {formatMessage({ ...messages.logout })}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
}
