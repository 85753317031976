import React from 'react';
import {ReactComponent as SubmittedIcon} from './assets/submitted.svg'
import {ReactComponent as SubmittedLateIcon} from './assets/submitted-late.svg'
import {ReactComponent as NotSubmittedIcon} from './assets/not-submitted.svg'
import {ReactComponent as LateIcon} from './assets/not-submitted-late.svg'
import {ReactComponent as AssessedIcon} from './assets/icon_bk_assessment_done.svg'
import {ReactComponent as AssessingIcon} from './assets/icon_bk_assessing.svg'
import {ReactComponent as NotAssessedIcon} from './assets/icon_bk_not_assessed.svg'
import colors from "./theme/colors";
import messages from './libs/i18n/messages'
import {routes} from "./components/App";
import {matchPath} from "react-router-dom";

export const SUBMITTED = 'SUBMITTED';
export const SUBMITTED_LATE = 'SUBMITTED_LATE';
export const NOT_SUBMITTED = 'NOT_SUBMITTED';
export const NOT_SUBMITTED_LATE = 'NOT_SUBMITTED_LATE'

export const ASSESSMENT_DONE = 'ASSESSMENT_DONE';
export const ASSESSING = 'ASSESSING';
export const NOT_ASSESSED = 'NOT_ASSESSED';


export const getSubmittedStatusIcon = (status, formatMessage) => {
  switch (status) {
    case SUBMITTED:
      return <div data-tooltip={formatMessage({ ...messages.submitted })}><SubmittedIcon/></div>;
    case SUBMITTED_LATE:
      return <div data-tooltip={formatMessage({ ...messages.submitted_late })}><SubmittedLateIcon/></div>;
    case NOT_SUBMITTED:
      return <div data-tooltip={formatMessage({ ...messages.not_submitted })}><NotSubmittedIcon/></div>;
    case NOT_SUBMITTED_LATE:
      return <div data-tooltip={formatMessage({ ...messages.not_submitted_late })}><LateIcon/></div>;
    default:
      return <div data-tooltip={formatMessage({ ...messages.not_submitted })}><NotSubmittedIcon/></div>;
  }
};

export const getAssessmentIcon = (status, formatMessage) => {
  switch (status) {
    case ASSESSMENT_DONE:
      return <div data-tooltip={formatMessage({ ...messages.assessed })}><AssessedIcon fill={colors.assessmentDone}/></div>;
    case ASSESSING:
      return <div data-tooltip={formatMessage({ ...messages.assessing })}><AssessingIcon fill={colors.assessing}/></div>;
    case NOT_ASSESSED:
      return <div data-tooltip={formatMessage({ ...messages.not_assessed })}><NotAssessedIcon fill={colors.notAssessed}/></div>;
  }
};

export const getUrlParams = (url) => {
  let params = {};
  Object.keys(routes).map(routeName => {
    const data = matchPath(url, {path: routes[routeName], exact: true, strict: false});
    params = {...params, ...data?.params};
  });
  return params;
};

export const adminCheck = (user, schoolId) => {
  return !!user?.adminSchoolIds?.find(a => a === parseInt(schoolId)) || !!user?.superAdminSchoolIds?.find(a => a === parseInt(schoolId));
};