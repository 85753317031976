import React from 'react';
import {useIntl} from 'react-intl';
import {colors, fonts} from '../../../theme';
import StudentCourseHeaderExpanded from './studentCourseHeaderExpanded';
import StudentCourseHeaderCollapsed from './studentCourseHeaderCollapsed';
import {useWindowDimensions} from '../../../libs/hooks';
import messages from './messages';
import ExpandComponent from './expandComponent';

const StudentTableHeader = (props) => {
  const {
    courses,
    expanded,
    selected,
    students,
    highlight,
    setSelected,
    setExpanded,
    expandCollapseAll,
    toggleHide,
    hiddenCourses,
  } = props;
  const dimensions = useWindowDimensions();
  const {formatMessage} = useIntl();

  const expandClicked = (course) => {
    if (expanded.includes(course.id)) {
      setExpanded(expanded.filter((e) => e !== course.id));
    } else {
      setExpanded([...expanded, course.id]);
    }
  };

  return (
    <div style={style.headerContainer}>
      <div
        style={{
          position: 'absolute',
          height: '250px',
          width: '251px',
          backgroundColor: colors.white,
          left: 0,
        }}
      >
        <ExpandComponent {...{setExpanded: expandCollapseAll}} />
        <div style={{...style.studentsTitleContainer, width: `${dimensions.width - 30}px`}}>
          <p style={{...fonts.MEDIUM, fontWeight: '800'}}>
            {formatMessage({...messages.students})}
          </p>
        </div>
      </div>
      <div style={style.rotatedContainer}>
        {courses.map((course) => {
          const isExpanded = !!expanded.find((e) => e === course.id);
          const isSelected = !!selected.find(
            (s) => s.courseId === course.id && s.studentId === 'all',
          );
          const isHidden = hiddenCourses?.find(h => h === course.id) || false;

          return (
            <div key={course.id}>
              <StudentCourseHeaderExpanded
                {...{
                  ...props,
                  course,
                  students,
                  highlight: highlight?.courseId === course.id,
                  expanded: isExpanded,
                  setSelected: (e, {checked}) => setSelected({checked, courseId: course.id}),
                  isSelected,
                  setExpanded: () => expandClicked(course),
                  toggleHide,
                  teachers: course?.teachers,
                  isHidden,
                }}
              />
              <StudentCourseHeaderCollapsed
                {...{
                  ...props,
                  course,
                  students,
                  highlight: highlight?.courseId === course.id,
                  expanded: isExpanded,
                  setSelected: (e, {checked}) => setSelected({checked, courseId: course.id}),
                  isSelected,
                  setExpanded: () => expandClicked(course),
                  toggleHide,
                  isHidden,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const style = {
  headerContainer: {
    backgroundColor: colors.white,
    overflow: 'scroll',
    margin: '-0px -10px -0px -0px',
    // padding: '0px 0px 0px 10px',
    marginLeft: '250px',
    boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
  },
  rotatedContainer: {
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(0px, 250px) rotate(-90deg)',
    transformOrigin: '0% 0%',
    height: '250px',
    borderTop: `1px solid ${colors.borderColor}`,
  },
  studentsTitleContainer: {
    boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
    position: 'absolute',
    top: '200px',
    left: 1,
    height: '50px',
    backgroundColor: colors.white,
    display: 'flex',
    alignItems: 'flex-end',
    padding: '10px 10px 10px 10px',
    border: `1px solid ${colors.borderColor}`,
  },
};

export default StudentTableHeader;
