import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import messages from './messages';
import {Accordion, Button, Card, Icon, Loader, Table} from 'semantic-ui-react';
import ObjectiveRequirements from './objectiveRequirements';
import {useQuery} from '@apollo/client';
import {gql} from 'apollo-boost';
import moment from 'moment';
import {colors} from '../../../theme';
import {getAssessmentIcon, getSubmittedStatusIcon} from "../../../utils";
import html2canvas from "html2canvas";

const STUDENT_PROGRESS_QUERY = gql`
  query($courseId: Int!, $studentId: Int!) {
    studentProgress(courseId: $courseId, studentId: $studentId) {
      id
      description
      feedback
      feedback_last_edit
      grade_last_edit
      grade_id
      target_grade_id
      target_grade_last_edit
      objectives
      assignments
      target_grade {
        id
        created
        modified
        name
        is_passed
        color
        label
        circle_label
        order
        grade_system_id  
      }
      grade {
        id
        created
        modified
        name
        is_passed
        color
        label
        circle_label
        order
        grade_system_id
      }
    }
  }
`;
const DATETIME_FORMAT = 'YYYY/MM/DD HH:mm';

const formatDate = (date) => {
  if (!date) {
    return null;
  }
  return moment(date).format(DATETIME_FORMAT);
};

const styles = {
  circleStyle: {
    width: '26px',
    height: '26px',
    minWidth: '26px',
    borderRadius: '20px',
    marginRight: '10px',
  },
}

export default function StudentProgress({studentName, courseName, courseId, studentId}) {
  const [showDescription, setShowDescription] = useState(false);
  const {formatMessage} = useIntl();

  const {loading, error, data} = useQuery(STUDENT_PROGRESS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {courseId: courseId, studentId: studentId},
  });

  function printImage(src) {
    const win = window.open(`about:blank`, "_new");
    win.document.open();
    win.document.write(
      `<html>
         <head>
           <link href="https://fonts.googleapis.com/css2?family=Bungee+Inline&family=Cabin&family=Roboto:wght@100;400&display=swap" rel="stylesheet">
           <style type="text/css" media="print">
              @page 
              {
                margin-left: 0.5in;
                margin-right: 0.5in;
                margin-top: 0.3in;
                margin-bottom: 0.3in;
              }
           </style>
         </head>
         <body onload="window.print()" onafterprint="window.close()">
              <span style="position: absolute;top:40px;font-family: 'Roboto', 'sans-serif'; font-size: 18">${studentName} - ${courseName}</span>
             <img style="width: 700px; margin-top: 50px" src="${src}"/>
         </body>
      </html>`);
    win.document.close();
  }

  const createImage = () => {
    html2canvas(document.getElementById("print_to_pdf")).then(canvas => {
      const data = canvas.toDataURL();
      printImage(data);
    });
  };

  if (loading) {
    return <Loader active={loading}/>;
  }

  if (error) {
    console.warn(error);
  }
  const {studentProgress: course} = data;

  return (
    <div style={{paddingTop: '15px'}}>
      <Button onClick={createImage}>{formatMessage({...messages.print})}</Button>
      <Card style={{width: '100%'}} id={"print_to_pdf"}>
        <Accordion>
          <Accordion.Title
            style={{padding: '10px'}}
            active={showDescription}
            onClick={() => setShowDescription(!showDescription)}
          >
            <Icon name="dropdown"/>
            {formatMessage({...messages.course_description})}
          </Accordion.Title>
          <Accordion.Content style={{padding: '10px'}} active={showDescription}>
            <div className="content" dangerouslySetInnerHTML={{__html: course.description}}/>
          </Accordion.Content>
        </Accordion>
        <Card.Content>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Table>
              <Table.Header>
                <Table.HeaderCell colSpan="2">
                  {formatMessage({...messages.overall_assessment})}
                </Table.HeaderCell>
                {/*<Table.HeaderCell>Lärare</Table.HeaderCell>*/}
                <Table.HeaderCell>{formatMessage({...messages.modified})}</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                <Table.Row error={course?.grade ? !course?.grade.is_passed : false}>
                  <Table.Cell singleLine collapsing>
                    {formatMessage({...messages.assessment})}
                  </Table.Cell>
                  <Table.Cell style={{fontWeight: 800, color: course?.grade?.color}}>
                    {' '}
                    {course?.grade?.label}
                  </Table.Cell>
                  <Table.Cell>{formatDate(course?.grade_last_edit)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell singleLine>{formatMessage({...messages.target_grade})}</Table.Cell>
                  <Table.Cell style={{fontWeight: 800, color: colors.blue}}> {course?.target_grade?.label}</Table.Cell>
                  <Table.Cell>{formatDate(course.target_grade_last_edit)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell> {formatMessage({...messages.feedback})} </Table.Cell>
                  <Table.Cell>{course.feedback} </Table.Cell>
                  <Table.Cell>{formatDate(course.feedback_last_edit)}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={6}>
                    {formatMessage({...messages.assignments})}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>{formatMessage({...messages.name})}</Table.HeaderCell>
                  <Table.HeaderCell textAlign={'center'}>{formatMessage({...messages.status})}</Table.HeaderCell>
                  <Table.HeaderCell textAlign={'center'}>
                    {formatMessage({...messages.current_assessment})}
                  </Table.HeaderCell>
                  <Table.HeaderCell>{formatMessage({...messages.feedback})}</Table.HeaderCell>
                  <Table.HeaderCell>{formatMessage({...messages.modified})}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {course.assignments?.sort((a, b) => a.title.localeCompare(b.title, 'sv'))?.map((assignment) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{
                          ...styles.circleStyle,
                          backgroundColor: assignment?.is_topic ? colors.topic : colors.assignment
                        }}/>
                        <span>{assignment?.title}</span>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{
                          display: 'flex',
                          width: '55px',
                          justifyContent: 'space-between'
                        }}>
                          {getAssessmentIcon(assignment?.status_new, formatMessage)}
                          {assignment?.use_submitted_status && getSubmittedStatusIcon(assignment?.submitted_status, formatMessage)}
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign={'center'} style={{color: assignment?.grade?.color}}>
                      {assignment?.grade?.label}
                    </Table.Cell>
                    <Table.Cell>{assignment?.feedback}</Table.Cell>
                    <Table.Cell>
                      {formatDate((assignment?.grade || assignment.feedback) ? assignment?.modified : '')}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table>
            {course.objectives.map((objective) => {
              const {assessment, assignments = []} = objective;
              const objectiveGrade = assessment?.grade;
              return (
                <Table key={objective.id}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="4">
                        <div>
                          <ObjectiveRequirements
                            title={objective.name}
                            requirements={objective.gradeRequirements || []}
                            description={objective.description}
                          />
                        </div>
                      </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell singleLine>
                        {formatMessage({...messages.current_assessment})}
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="3">
                        {formatMessage({...messages.feedback})}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row
                      verticalAlign="top"
                      style={{height: '70px'}}
                      error={objectiveGrade ? !objectiveGrade.is_passed : false}
                    >
                      <Table.Cell
                        collapsing
                        style={{fontWeight: 800, color: objectiveGrade?.color}}
                      >
                        {objectiveGrade?.name}
                      </Table.Cell>
                      <Table.Cell colSpan="2">{assessment.feedback}</Table.Cell>
                      <Table.Cell>{formatDate(assessment.grade_last_edit)}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                  <Table.Header>
                    <Table.HeaderCell style={{}}>
                      {formatMessage({...messages.assignment})}
                    </Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      {formatMessage({...messages.current_assessment})}
                    </Table.HeaderCell>
                    <Table.HeaderCell>{formatMessage({...messages.feedback})}</Table.HeaderCell>
                    <Table.HeaderCell>{formatMessage({...messages.modified})}</Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {assignments.map((assignment) => {
                      const passed = assignment?.assessment?.grade?.is_passed;
                      const gradeLastEdit = assignment?.assessment?.grade_last_edit;
                      const feedbackLastEdit = assignment?.assessment?.feedback_last_edit;
                      let lastEdit;
                      if (feedbackLastEdit && gradeLastEdit) {
                        lastEdit = moment(gradeLastEdit).isAfter(moment(feedbackLastEdit))
                          ? gradeLastEdit
                          : feedbackLastEdit;
                      } else if (feedbackLastEdit) {
                        lastEdit = feedbackLastEdit;
                      } else if (gradeLastEdit) {
                        lastEdit = gradeLastEdit;
                      } else {
                        lastEdit = null;
                      }

                      return (
                        <Table.Row key={assignment.id} error={!(passed !== false)}>
                          <Table.Cell collapsing singleLine>
                            <div style={{display: 'flex', alignItem: 'center'}}>
                              <div style={{
                                ...styles.circleStyle,
                                backgroundColor: assignment?.is_topic ? colors.topic : colors.assignment
                              }}/>
                              {assignment.title}
                            </div>
                          </Table.Cell>
                          <Table.Cell
                            collapsing
                            style={{fontWeight: 800, color: assignment?.assessment?.grade?.color}}
                          >
                            {assignment?.assessment?.grade?.name}
                          </Table.Cell>
                          <Table.Cell>{assignment?.assessment?.feedback}</Table.Cell>
                          <Table.Cell>{formatDate(lastEdit)}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              );
            })}
          </div>
        </Card.Content>
      </Card>
    </div>
  );
}
