const base = {
  black: '#000407',
  white: '#ffffff',
  textColor: '#4A4A4A',
  primary: '#FBCD03',
  blue: '#387AFF',
  blueText: '#4183C4',
  grey: '#F5F5F5',
  red: '#FA4D56',
  redOpacity: '#FA4D5699',
  assessing: '#fcac33',
  notAssessed: '#63656a',
  green: '#6FDC8C',
  assessmentDone: '#4caf50',
  inactive: '#DFDFDF',
  borderColor: '#F6F6F6',
  borderColorDark: '#AAAAAA',
  borderColorReallyDark: '#888',
  dimmed: '#00000080',
  textColorBrighter: '#929293',
  greyOpacity: '#F5F5F590',
  darkGrey: '#444',
  assignment: '#38C88A',
  topic: '#177c84',
};
const background = {
  stripedGreenBg: `repeating-linear-gradient(60deg, ${base.white}, ${base.white} 2px,${base.green} 2px, ${base.green} 4px)`,
  stripedYellowBg: `repeating-linear-gradient(60deg, ${base.white}, ${base.white} 2px,${base.primary} 2px, ${base.primary} 4px)`,
  stripedRedBg: `repeating-linear-gradient(60deg, ${base.white}, ${base.white} 2px,${base.redOpacity} 2px, ${base.redOpacity} 4px)`,
};
export default {
  ...base,
  ...background,
};
