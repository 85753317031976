import React, { useState } from 'react';
import arrow from '../../../assets/arrow.svg';
import { colors } from '../../../theme';

export default function ExpandComponent(props) {
  const {setExpanded} = props;
  const [expand, setExpand] = useState(false);

  const onClick=(ex)=>{
    setExpand(ex);
    setExpanded(ex);
  };
  return (
    <div style={style.container}>
      {!expand &&
      <div style={style.button} onClick={() => onClick(true)}>
        <img src={arrow} style={{ transform: 'rotate(-180deg)' }} />
        <img src={arrow} style={{ transform: 'rotate(0deg)', marginLeft: '-6px' }} />
      </div>
      }
      {expand &&
      <div style={style.button} onClick={() => onClick(false)}>
        <img src={arrow} style={{ transform: 'rotate(0deg)' }} />
        <img src={arrow} style={{ transform: 'rotate(-180deg)', marginLeft: '2px' }} />
      </div>
      }
    </div>
  );
}

const style = {
  container: {
    border: '0px solid grey',
    backgroundColor: 'transparent',
    position: 'absolute',
    left: 200,
  },
  button: {
    cursor: 'pointer',
    width: '47px',
    height: '40px',
    border: `1px solid ${colors.borderColor}`,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};
