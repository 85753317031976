import colors from './colors';
const baseFonts = {
  XXXLARGE: {
    color: colors.textColor,
    fontSize: '62px',
  },
  XXLARGE: {
    color: colors.textColor,
    fontSize: '48px',
  },
  XLARGE: {
    color: colors.textColor,
    fontSize: '30px',
  },
  LARGE: {
    color: colors.textColor,
    fontSize: '20px',
  },
  MEDIUM: {
    color: colors.textColor,
    fontSize: '14px',
  },
  SMALL: {
    color: colors.textColor,
    fontSize: '12px',
  },
  TINY: {
    color: colors.textColor,
    fontSize: '11px',
  },
};
// TODO Change to google font "Cabin"
export default {
  LARGE: {
    ...baseFonts.LARGE,
    fontFamily: "Roboto",
    // fontFamily: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
},
  MEDIUM: {
    ...baseFonts.MEDIUM,
    fontFamily: "Roboto",
  },
  SMALL: {
    ...baseFonts.SMALL,
    fontFamily: "Roboto",
  },
  TINY: {
    ...baseFonts.TINY,
    fontFamily: "Roboto",
  },
};
