import {colors} from "../../theme";
import {animated, useSpring} from "react-spring";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useIntl} from "react-intl";
import messages from "./messages";

const ANIMATE_FROM = -50;
const ANIMATE_TO = 60;

const UpdatingInfo = () => {
  const [open, setOpen] = useState(false);
  const updating = useSelector(state => state.app.updating);
  const {formatMessage} = useIntl();

  useEffect(() => {
    if (!open && updating) {
      set({top: ANIMATE_TO});
      setOpen(true);
    }
    if (open && !updating) {
      setTimeout(() => {
        set({top:ANIMATE_FROM});
        setOpen(false);
      }, 100);
    }
  }, [updating]);

  const [animProp, set] = useSpring(() => ({
    top: ANIMATE_FROM,
    from: {top: ANIMATE_FROM},
    config: {mass: 1, tension: 180, friction: 16},
    onRest: (props) => {
      console.log('onRest: ', props);
    },
  }));

  return (
    <animated.div style={{
      ...animProp,
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      left: '50%',
      padding: '0px 20px 0px 20px',
      border: `1px solid ${colors.borderColorReallyDark}`,
      height: '40px',
      borderRadius: '6px',
      zIndex: 10000,
      backgroundColor: colors.primary
    }}>
      <span>{formatMessage({...messages.updating})}</span>
    </animated.div>
  )
};

export default UpdatingInfo;