import React, {useReducer} from 'react';
import {CombinedStatus, ExtraAdjustmentStatus, GradeStatus, SmileyStatus} from './status';
import {colors} from '../../../theme';
import * as EWSutils from '../utils';
import NoAssessment from './status/noAssessment';
import {useMutation} from '@apollo/client';
import {ADD_EWS_COMMENT, PUBLISH_EWS_STATUS, UPDATE_EWS_STATUS} from "../../../client";
import {setError, setUpdating} from "../../../redux/reducers/app";
import {useDispatch} from "react-redux";

export default function StudentCourse(props) {
  const {
    onSave,
    availableAdjustments,
    students,
    course,
    setStudentCourse,
    expanded,
    setSelected,
    selected,
    selectMode,
    setHighlight,
  } = props;
  const [publishStatus] = useMutation(PUBLISH_EWS_STATUS);
  const [updateEws] = useMutation(UPDATE_EWS_STATUS);
  const [addEwsComment] = useMutation(ADD_EWS_COMMENT);
  const dispatch = useDispatch();

  const openDetails = (studentId, courseId, studentProgress = false) => {
    setStudentCourse({
      editable: true,
      studentId: studentId,
      courseId: courseId,
      studentProgress,
    });
  };

  const onHover = (data) => {
    setHighlight(data);
  };

  const saveStatus = async (data, assessment, student) => {
    dispatch(setUpdating(true));
    try {
      if (data.status) {
        await updateEws({
          variables: {
            status: {
              student_id: student.id,
              course_id: course.id,
              status: data.status,
              published: data.publish === 'publish',
            },
          },
        })
      }
      if (data.comment) {
        await addEwsComment({
          variables: {
            comment: data.comment,
            courseId: course.id,
            studentId: student.id,
          }
        });
      }
      if (data.publish && data.publish !== '') {
        await publishStatus({
          variables: {
            studentId: student.id,
            courseId: course.id,
            published: data.publish === 'publish',
          },
        });
      }
      onSave();
    } catch (e) {
      console.log('error: ', e);
      onSave();
      dispatch(setError({title:'Error', message:'Could not save status, try again. If problem persists, contact StudyBee support.'}))
    }
  };

  return (
    <div style={{position: 'relative'}}>
      {students.map((student, i) => {
        const backgroundColor = i % 2 ? colors.white : colors.borderColor;
        const assessment = student.courseAssessments.find((c) => c.course_definition_id === course.id);
        const key = `${course.id}:${student.id}`;
        if (!assessment) {
          return (
            <NoAssessment
              key={key}
              {...{
                studentId: student.id,
                courseId: course.id,
                expanded,
                backgroundColor,
                onHover,
              }}
            />
          );
        }
        const extraAdjustments = EWSutils.getCurrentExtraAdjustments(student?.extraAdjustmentsLog.filter(log => log.course_definition_id === course.id));
        if (!expanded) {
          return (
            <div key={key} style={{display: 'flex'}}>
              <CombinedStatus
                status={student?.courseStatus.find(c => c.courseId === course.id)}
                expanded={expanded}
                grade={assessment?.gradeName}
                extraAdjustments={extraAdjustments.length}
                click={() => openDetails(student.id, course.id)}
                selected={!!selected.find(
                  (s) => s.courseId === course.id && s.studentId === student.id,
                )}
                onSelect={() => {
                  setSelected({studentId: student.id, courseId: course.id, checked: !selected.find(
                      (s) => s.courseId === course.id && s.studentId === student.id,
                    )})
                }}
                selectMode={selectMode}
                comment={assessment.feedback}
                onHover={onHover}
                studentId={student.id}
                courseId={course.id}
              />
            </div>
          );
        } else {
          let status = student?.courseStatus.find(c => c.courseId === course.id);
          return (
            <div key={key} style={{display: 'flex', flexDirection: 'row', backgroundColor}}>
              <CombinedStatus
                status={status}
                expanded={expanded}
                grade={assessment?.gradeName}
                extraAdjustments={extraAdjustments.length}
                click={() => openDetails(student.id, course.id)}
                selected={!!selected.find(
                  (s) => s.courseId === course.id && s.studentId === student.id,
                )}
                onSelect={() => {
                  setSelected({studentId: student.id, courseId: course.id, checked: !selected.find(
                      (s) => s.courseId === course.id && s.studentId === student.id,
                    )})
                }}
                selectMode={selectMode}
                comment={assessment.feedback}
                onHover={onHover}
                studentId={student.id}
                courseId={course.id}
              />
              <SmileyStatus
                status={status?.adminStatus}
                setByAdmin={true}
                onSave={(data) => saveStatus(data, assessment, student)}
                editable={true}
              />
              <SmileyStatus status={status?.systemStatus} setByAdmin={false}/>
              <GradeStatus
                grade={assessment?.gradeName}
                feedback={assessment?.feedback}
                openStudentProgress={() => openDetails(student.id, course.id, true)}
              />
              <ExtraAdjustmentStatus
                extraAdjustments={extraAdjustments}
                availableAdjustments={availableAdjustments}
                courseId={course.id}
                studentId={student.id}
                onSave={onSave}
              />
            </div>
          );
        }
      })}
    </div>
  );
}
