import { colors } from '../../../../theme';
import React from 'react';

export default function NoAssessment(props) {
  const { onHover, studentId, courseId, expanded, backgroundColor } = props;

  const hover = () => {
    onHover({ studentId, courseId });
  };

  return (
    <div
      onMouseOver={hover}
      key={studentId}
      style={{ display: 'flex' }}
    >
      <div
        style={{
          width: `37px`,
          height: '47px',
          borderBottom: `1px solid ${colors.borderColorDark}`,
          backgroundColor: colors.inactive,
        }}
      />
      <div
        style={{
          width: `${expanded ? 38 * 4 + 1 : 0}px`,
          height: '47px',
          borderRight: `${expanded ? 1 : 1}px solid ${expanded ? '#000' : colors.borderColorDark}`,
          borderBottom: `1px solid ${colors.borderColorDark}`,
          backgroundColor,
        }}
      />
    </div>
  );
}
