import { fonts } from '../../../theme';
import { Button, Checkbox, Popup, TextArea } from 'semantic-ui-react';
import React, {useState} from 'react';
import EWSStatusRadioButton from '../EWSStatusRadioButton';
import { statusToAPIStatus } from '../utils';
import messages from './messages';
import globalMessages from '../../../libs/i18n/messages';
import { useIntl } from 'react-intl';

export default function StatusPopup(props) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState();
  const [publish, setPublish] = useState('');
  const [comment, setComment] = useState();
  const { onClick, trigger, disabled, basic = false } = props;
  const { formatMessage } = useIntl();

  const click = () => {
    setOpen(false);
    setPublish('');
    onClick({ status: statusToAPIStatus(status), comment, publish });
  };
  return (
    <Popup
      on={'click'}
      disabled={disabled}
      basic={basic}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => false}
      position="bottom left"
      trigger={trigger}
    >
      <Popup.Content style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ ...fonts.MEDIUM, alignSelf: 'center' }}>
              {formatMessage({ ...messages.teacher_status })}
            </p>
            <EWSStatusRadioButton color={'green'} isStatusByAdmin={true} onChange={setStatus} />
            <EWSStatusRadioButton color={'yellow'} isStatusByAdmin={true} onChange={setStatus} />
            <EWSStatusRadioButton color={'red'} isStatusByAdmin={true} onChange={setStatus} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
            <p style={{ ...fonts.MEDIUM, alignSelf: 'center' }}>{formatMessage({...messages.visibility})}</p>
            <div style={{ padding: '5px', display: 'flex' }}>
              <Checkbox
                radio
                checked={publish === 'publish'}
                onChange={(e, { checked }) => {
                  setPublish('publish');
                }}
              />
              <span>{formatMessage({...messages.visible})}</span>
            </div>
            <div style={{ padding: '5px', display: 'flex' }}>
              <Checkbox
                radio
                checked={publish === 'not_publish'}
                onChange={(e, { checked }) => {
                  setPublish('not_publish');
                }}
              />
              <span>{formatMessage({...messages.not_visible})}</span>
            </div>
            <div style={{ padding: '5px', display: 'flex' }}>
              <Checkbox
                radio
                checked={publish === ''}
                onChange={(e, { checked }) => {
                  setPublish('');
                }}
              />
              <span style={{ whiteSpace: 'nowrap' }}>{formatMessage({...messages.dont_change_visibility})}</span>
            </div>
          </div>
          {/*<div*/}
          {/*  style={{*/}
          {/*    paddingLeft: '10px',*/}
          {/*    marginLeft: '10px',*/}
          {/*    borderLeft: `0px solid ${colors.borderColorDark}`,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <p style={fonts.MEDIUM}>{formatMessage({ ...messages.add_comment })}</p>*/}
          {/*  <TextArea*/}
          {/*    onChange={({ target }) => setComment(target.value)}*/}
          {/*    style={{ minHeight: '100px', marginRight: '4px' }}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <Button
          style={{ marginTop: '5px' }}
          content={formatMessage({ ...globalMessages.ok })}
          onClick={click}
        />
        <Button
          style={{ marginTop: '5px' }}
          content={formatMessage({ ...globalMessages.cancel })}
          onClick={() => {
            setPublish('');
            setOpen(false);
          }}
        />
      </Popup.Content>
    </Popup>
  );
}

const styles = {
  popupIconStyleContainer: {
    borderRadius: '10px',
    marginBottom: '5px',
    cursor: 'pointer',
  },
  popupIconStyle: {
    width: '20px',
    height: '20px',
    margin: '4px 20px 2px 2px',
  },
};
