import React from 'react'
import _ from 'lodash'
import {
    // Label,
    Checkbox,
    Button,
} from 'semantic-ui-react'

export default function EWSStatusRadioButton(props) {
    const { onChange, color, checked, description, isStatusByAdmin } = props
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 4,
                marginLeft: 2,
            }}
        >
            <Checkbox
                radio
                checked={checked}
                onChange={(e, { checked }) => {
                    onChange(color)
                }}
            />
            <div>
                <Button
                    color={color.toLowerCase()}
                    style={{ opacity: isStatusByAdmin ? '1' : '0.6' }}
                    onClick={() => {
                        onChange(color)
                    }}
                >
                    {' '}
                </Button>
                {description && (
                    <div>
                        <i>{description}</i>
                    </div>
                )}
            </div>
        </div>
    )
}
