import React from 'react';
import icon from '../../../assets/list.svg';
import { fonts, colors } from '../../../theme';
import { Checkbox } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

export default function StudentItem({ student, bgColor, selectMode, setSelected, schoolId, highlight }) {
  return (
    <div style={{ ...style.container, backgroundColor: highlight ? colors.inactive : bgColor }}>
      {selectMode && (
        <div style={{ ...style.checkboxContainer, backgroundColor: highlight ? colors.inactive : colors.white  }}>
          <Checkbox onChange={setSelected} />
        </div>
      )}
      <img alt={''} style={style.avatar} src={student.photo_url} />
      <div style={{ display: 'flex', flex: 1 }}>
        <NavLink to={`/ews/${schoolId}/students/${student.id}`}>
          <p
            style={{
              ...fonts.MEDIUM,
              color: colors.blueText,
              width: selectMode ? '110px' : '155px',
              paddingRight: '10px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {student.first_name} {student.last_name}
          </p>
        </NavLink>
      </div>
      {/*<img alt="" style={{ width: '14px', height: '10px', marginRight: '15px' }} src={icon} />*/}
    </div>
  );
}

const style = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.borderColorDark}`,
    borderRight: `1px solid ${colors.borderColorDark}`,
    borderLeft: `1px solid ${colors.borderColorDark}`,
    height: '47px',
    width: '250px',
  },
  avatar: {
    height: '37px',
    width: '37px',
    borderRadius: '25px',
    margin: '0px 15px 0px 15px',
    resizeMode: 'contain',
    boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
  },
  checkboxContainer: {
    border: `2px solid ${colors.borderColor}`,
    borderBottom: '0px',
    height: '47px',
    width: '47px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};
