import React, {useState} from 'react';
import {Accordion, Icon, Table} from 'semantic-ui-react';
import {fonts} from "../../../theme";


export default function ObjectiveRequirements(props) {
  const {requirements, title, description} = props;
  const [showDesc, setShowDesc] = useState(false);
  return (
    <Accordion>
      <Accordion.Title
        style={{padding: '0px'}}
        active={showDesc}
        onClick={() => setShowDesc(!showDesc)}>
        <Icon name="dropdown"/>
        {title}
      </Accordion.Title>
      <Accordion.Content active={showDesc}>
        <div>
          {description &&
            <div className="content" dangerouslySetInnerHTML={{__html: description}}></div>
          }
          {requirements.map((r) => {
            return (
              <Table key={r.id}>
                <Table.Row>
                  <Table.Cell><h4 style={{color: r.grade.color}}>{r.grade.label}</h4></Table.Cell>
                  <Table.Cell>
                    <div className="content" dangerouslySetInnerHTML={{__html: r.description}}></div>
                  </Table.Cell>
                </Table.Row>
              </Table>
            );
          })}
        </div>
      </Accordion.Content>
    </Accordion>
  );
}
