import axios from 'axios';
import authLib from '../auth';
import store from '../../redux/store';
import * as authActions from '../../redux/reducers/auth';
import log from '../log';

const HOST = process.env.REACT_APP_API || 'http://localhost:8082';

const request = axios.create({
  baseURL: `${HOST}/`,
  timeout: 10000,
});

request.interceptors.request.use(
  async (config) => ({
    ...config,
    headers: {
      Authorization: `Token ${authLib.getAccessToken()}`,
    },
  }),
  (error) => Promise.reject(error),
);
request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (typeof error.response === 'undefined') {
      log.error('A network error occurred. This could be a CORS issue or a dropped internet connection. It is not possible for us to know.');
    }
    if ([403, 401].includes(error.response.status)) {
      log.error('401', 'authentication error');
      authLib.logout();
      store.dispatch(authActions.logout());
    }

    log.error('response', error.toString());
    return Promise.reject(error);
  },
);

export default request;
