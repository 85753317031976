import {Popup} from 'semantic-ui-react';
import React, {useState} from 'react';
import ExtraAdjustmentPopupContent from "./extraAdjustmentPopupContent";


export default function ExtraAdjustmentPopup(props) {
  const {
    id,
    onClose,
    trigger,
    disabled,
    adjustments,
    selectedAdjustments: initialSelected,
    partialSelectedAdjustments: initialPartial,
    basic = false,
  } = props;

  const [open, setOpen] = useState(false);

  const close = (data) => {
    if (data) {
      onClose(data);
    }
    setOpen(false);
  };

  return (
    <Popup
      style={{zIndex: 3000}}
      on={'click'}
      disabled={disabled}
      basic={basic}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => false}
      // position="bottom center"
      // pinned
      position="top left"
      trigger={trigger}
    >
      <Popup.Content style={{display: 'flex', flexDirection: 'column'}}>
        <ExtraAdjustmentPopupContent
          id={id}
          onClose={close}
          adjustments={adjustments}
          selectedAdjustments={initialSelected}
          partialSelectedAdjustments={initialPartial}
        />
      </Popup.Content>
    </Popup>
  );
}
