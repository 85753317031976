import React from 'react'
import { Button } from 'semantic-ui-react'
import { useMutation } from '@apollo/client';
import { gql } from 'apollo-boost'
// import PageLoader from '../PageLoader'

const SYNC_G_SUITE = gql`
    mutation {
        syncGSuite
    }
`

const SYNC_G_C_GUARDIANS = gql`
    mutation {
        syncGCGuardians
    }
`

export default function SyncData(props) {
    const [syncGSuite, syncGSuiteData] = useMutation(SYNC_G_SUITE)
    const [syncGCGuardians, syncGCGuardiansData] = useMutation(SYNC_G_C_GUARDIANS)
    return (
        <div>
            <Button
                loading={syncGSuiteData.loading}
                onClick={() => {
                    syncGSuite()
                }}
            >
                Sync mentors and classes
            </Button>
            <div>
                <pre
                    style={{
                        maxHeight: 200,
                        overflowY: 'scroll',
                    }}
                >
                    {JSON.stringify(syncGSuiteData.data, null, 2)}
                </pre>
            </div>
            <Button
                loading={syncGCGuardiansData.loading}
                onClick={() => {
                    syncGCGuardians()
                }}
            >
                Sync Guardians
            </Button>
            <div>
                <pre
                    style={{
                        maxHeight: 200,
                        overflowY: 'scroll',
                    }}
                >
                    {JSON.stringify(syncGCGuardiansData.data, null, 2)}
                </pre>
            </div>
        </div>
    )
}
