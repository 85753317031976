const PREFIX = 'APP';
export const APP_ERROR = `${PREFIX}_ERROR`;
export const APP_UPDATING = `${PREFIX}_UPDATING`;

export const setError = (payload = {}) => ({
  type: APP_ERROR,
  payload,
});
export const setUpdating = (payload = {}) => ({
  type: APP_UPDATING,
  payload,
});

const INITIAL_STATE = {error: null, updating: null};
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case APP_ERROR:
      return {...state, error: action.payload};
    case APP_UPDATING:
      return {...state, updating: action.payload};
    default:
      return state;
  }
}
