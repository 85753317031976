import React, {useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {fonts} from '../../../theme';
import mediumIcon from '../../../assets/medium-face.svg';
import extraHelpIcon from '../../../assets/extra-help.svg';
import checkmarkIcon from '../../../assets/check.svg';
import {StatusPopup, ExtraAdjustmentPopup} from '../QuickActions';
import {getCurrentExtraAdjustments} from '../utils';
import messages from './messages';

export default function SelectOptions(props) {
  const {
    onStatusClick,
    onExtraAdjustmentsSave,
    onAcceptStatus,
    enabled,
    adjustments,
    selectedStudentsAndCourses,
    students,
  } = props;

  const {formatMessage} = useIntl();

  console.log({props});
  const currentAdjustments = selectedStudentsAndCourses
    .map((sel) => {
      const student = students.find(s => s.id === sel.studentId);
      if(!student){
        return [];
      }
      return getCurrentExtraAdjustments(student.extraAdjustmentsLog.filter(l => l.course_definition_id === sel.courseId));
    })
    .flat();

  let partialSelectedAdjustments = [...new Set(currentAdjustments)];

  const selectedAdjustments = useMemo( () => partialSelectedAdjustments.filter((id) => {
    return currentAdjustments.filter((i) => i === id).length === selectedStudentsAndCourses.length;
  }), [selectedStudentsAndCourses]);

  partialSelectedAdjustments = partialSelectedAdjustments.filter(
    (id) => !selectedAdjustments.includes(id),
  );
  const partial =  useMemo(() => partialSelectedAdjustments.map(p => {
    return {id: p, count: currentAdjustments.filter(c => c === p)?.length, total: selectedStudentsAndCourses.length};
  }), [selectedStudentsAndCourses]);

  return (
    <div
      style={{
        width: '240px',
        height: '200px',
        backgroundColor: 'transparent',
        position: 'absolute',
        opacity: enabled ? '1' : '0.3',
        zIndex: 1001,
        margin: '45px 0px 15px 10px',
      }}
    >
      <p style={{...fonts.MEDIUM, fontWeight: 800}}>{formatMessage({...messages.change_selected_objects})}</p>
      <StatusPopup
        basic={false}
        onClick={(status) => {
          const r = window.confirm(formatMessage({...messages.change_many_warning}));
          if (r) {
            onStatusClick(status);
          }
        }}
        trigger={
          <div style={style.item}>
            <img alt={':|'} src={mediumIcon} style={style.icon}/>
            <p style={fonts.MEDIUM}>{formatMessage({...messages.teacher_status})}</p>
          </div>
        }
      />

      <ExtraAdjustmentPopup
        id={'multi'}
        selectedAdjustments={selectedAdjustments}
        partialSelectedAdjustments={partial}
        adjustments={adjustments}
        onClose={(data) => {
          const r = window.confirm(formatMessage({...messages.change_many_warning}));
          if (r) {
            onExtraAdjustmentsSave(data);
          }
        }}
        trigger={
          <div style={style.item}>
            <img alt={'O'} src={extraHelpIcon} style={style.icon}/>
            <p style={fonts.MEDIUM}>{formatMessage({...messages.set_extra_adjustments})}</p>
          </div>
        }
      />

      <div
        style={style.item}
        onClick={() => {
          const r = window.confirm(formatMessage({...messages.change_many_warning}));
          if (r) {
            onAcceptStatus();
          }
        }}
      >
        <img alt={'V'} src={checkmarkIcon} style={style.icon}/>
        <p style={fonts.MEDIUM}>{formatMessage({...messages.accept_suggested_status})}</p>
      </div>

      {/*<div style={style.item}>*/}
      {/*  <img alt={'V'} src={checkmarkIcon} style={style.icon} />*/}
      {/*  <p>Markera alla</p>*/}
      {/*</div>*/}
      {!enabled && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#00000000',
          }}
        />
      )}
    </div>
  );
}

const style = {
  item: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    margin: '10px',
  },
};
