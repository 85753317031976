import DummyStatus from './dummyStatus';
import { fonts } from '../../../theme';
import React from 'react';

const LegendItem = (props) => {
  const { description } = props;
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom:'15px' }}>
      <DummyStatus {...props} />
      <p style={{ ...fonts.MEDIUM, marginLeft: '20px' }}>
        {description}
      </p>
    </div>
  );
};

export default LegendItem;
