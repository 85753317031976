import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { gql } from 'apollo-boost';
import { Button, Form, Message } from 'semantic-ui-react';
import { colors, fonts } from '../../theme';
import messages from './messages';
import PageLoader from '../PageLoader';
import StudyBeeLogo from '../../assets/studybee_logo.png';
import {logout} from "../../redux/reducers/auth";
import {useDispatch} from "react-redux";

const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

const StudybeeEmailLogin = (props) => {
  const { login, back, onError } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [doLogin, { error: loginError }] = useMutation(LOGIN, {
    onError: (error) => {
      console.log('error:', error);
      dispatch(logout());
    },
  });

  useEffect(() => {
    if (loginError) {
      setLoading(false);
      onError(formatMessage({ ...messages.loginError }));
    }
  }, [loginError]);

  const {
    register, handleSubmit, errors, setValue, triggerValidation,
  } = useForm();

  useEffect(() => {
    register({ name: 'email' }, { required: formatMessage({ ...messages.email_required }) });
    register({ name: 'password' }, { required: formatMessage({ ...messages.password_required }) });
  }, [register]);

  if (loading) {
    return <PageLoader />;
  }

  const requestLogin = async ({ email, password }) => {
    setLoading(true);
    const data = await doLogin({ variables: { email, password } });
    const token = data?.data?.login?.token;
    login(token);
  };
  const handleChange = async (e, { name, value }) => {
    setValue(name, value);
    await triggerValidation({ name });
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '450px',
        alignItems: 'center',
        flexDirection: 'column',
        border: `1px solid ${colors.borderColor}`,
        backgroundColor: colors.white,
        borderRadius: '5px',
        boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
        padding: '20px',
      }}
    >
      <img
        src={StudyBeeLogo}
        style={{ width: '250px' }}
      />
      <p style={fonts.MEDIUM}>{formatMessage({ ...messages.no_studybee_account })}</p>
      <Form
        style={{ width: '400px' }}
        size="large"
        onSubmit={handleSubmit((variables) => {
          requestLogin(variables);
        })}
      >
        <Form.Input
          fluid
          icon="user"
          iconPosition="left"
          placeholder={formatMessage({ ...messages.email })}
          name="email"
          onChange={handleChange}
        />
        {errors.email && <Message error visible content={errors.email.message} />}
        <Form.Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder={formatMessage({ ...messages.password })}
          type="password"
          name="password"
          onChange={handleChange}
        />
        {errors.password && <Message error visible content={errors.password.message} />}

        <Button color="yellow" fluid size="large">
          {formatMessage({ ...messages.login })}
        </Button>
      </Form>
      <Button style={{ marginTop: '15px', marginLeft: '1px' }} onClick={back} color="yellow" fluid size="large">{formatMessage({ ...messages.back })}</Button>
    </div>
  );
};

export default StudybeeEmailLogin;
