import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { useIntl } from 'react-intl'
import messages from './messages'

export default function EWSExtraAdjustmentsContainer(props) {
    const { children } = props
    const { formatMessage } = useIntl()
    return (
        <Dimmer style={{zIndex:10000}} active page>
            <Loader>{children || formatMessage({ ...messages.loading })}</Loader>
        </Dimmer>
    )
}
