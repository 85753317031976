import { colors, fonts } from '../../../../theme';
import React from 'react';
import happyIcon from '../../../../assets/happy-face.svg';
import mediumIcon from '../../../../assets/medium-face.svg';
import { StatusPopup } from '../../QuickActions';
import { Popup, PopupContent } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import messages  from '../messages';

export default function SmileyStatus({ status, setByAdmin, onSave, editable, hideBottomBorder, backgroundColor }) {
  const { formatMessage } = useIntl();
  let icon;
  let color = colors.white;
  let background;
  switch (status) {
    case '30':
      icon = mediumIcon;
      color = colors.red;
      background = colors.stripedRedBg;
      break;
    case '10':
      icon = happyIcon;
      color = colors.green;
      background = colors.stripedGreenBg;
      break;
    case '20':
      icon = mediumIcon;
      color = colors.primary;
      background = colors.stripedYellowBg;
      break;
    default:
      break;
  }

  const image = (
    <img
      alt={':)'}
      src={icon ? icon : mediumIcon}
      style={{
        width: '20px',
        height: '20px',
        opacity: icon ? 1 : 0.5,
        borderRadius: '30px',
        background: setByAdmin ? null : background,
        backgroundColor: setByAdmin ? color : colors.white,
        cursor: 'pointer',
      }}
    />
  );

  return (
    <div
      style={{
        width: '38px',
        height: '47px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `${hideBottomBorder?0:1}px solid ${colors.borderColorDark}`,
        backgroundColor,
      }}
    >
      {editable && (
        <StatusPopup
          {...{
            onClick: onSave,
            basic: true,
            trigger: image,
          }}
        />
      )}
      {!editable && (
        <Popup trigger={image} on={'click'} basic>
          <PopupContent style={{ width: '300px' }}>
            <p style={fonts.MEDIUM}>
              {formatMessage({ ...messages.suggested_status_tip_text })}
            </p>
          </PopupContent>
        </Popup>
      )}
    </div>
  );
}
