import {colors} from '../../../theme';
import {Button} from 'semantic-ui-react';
import store from '../../../libs/store';
import React, {useReducer} from 'react';
import {useIntl} from 'react-intl';
import messages from '../../../libs/i18n/messages';
import {useWindowDimensions} from '../../../libs/hooks';

const Splash = ({url, onClose}) => {
  const {formatMessage} = useIntl();
  const dimensions = useWindowDimensions();

  const height = dimensions.height - 200 > 650 ? 650 : dimensions.height - 200;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10000,
        backgroundColor: colors.dimmed,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '850px',
          maxHeight: '650px',
          height: `${height}px`,
          backgroundColor: colors.white,
          border: `1px solid${colors.borderColor}`,
          borderRadius: '10px',
          transform: 'translateZ(0px)',
          overflow: 'hidden',
          alignItems: 'flex-end',
        }}
      >
        <div
          style={{padding: '20px'}}
          dangerouslySetInnerHTML={{
            __html: `<iframe src="${url}" width="810px" height="${
              height - 95
            }px" frameBorder="0"/>`,
          }}
        />
        <div
          style={{
            backgroundColor: colors.primary,
            width: '100%',
            height: '50px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            style={{marginTop: '4px', height: '36px'}}
            onClick={() => {
              onClose();
            }}
          >
            {formatMessage({...messages.close})}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Splash;
