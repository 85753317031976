import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout, logout as reduxLogout, updateTimeLeftToExpire } from '../../redux/reducers/auth';
import authLib from '../../libs/auth';

let tokenExpireIntervalMinutes;
let tokenExpireIntervalSeconds;
let tokenExpireGraceTimeout;
let tokenExpireOneMinLeftTimeout;
let tokenInfo;

const TOKEN_LIFE_TIME = false; //1000 * 60 * 3; // Total lifetime of token, used for debugging
export const TOKEN_EXPIRE_GRACE_PERIOD = 1000 * 60 * 10; // 10 min period with dialog in the corner
export const TOKEN_EXPIRE_BLOCK_UI_THRESHOLD = 1000 * 60 * 1; // 1min where dialog is blocking ui

const TokenExpireChecker = () => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const updateTimeLeft = () => {
    const timeLeft = tokenInfo?.expiry_date - Date.now() - (TOKEN_LIFE_TIME ? (3600000 - TOKEN_LIFE_TIME) : 0);
    // console.log('xxx timeLeft: ', `${timeLeft / (1000 * 60)} min`);
    dispatch(updateTimeLeftToExpire(timeLeft));
    return timeLeft;
  };

  const stopAll = () => {
    clearTimeout(tokenExpireGraceTimeout);
    clearTimeout(tokenExpireOneMinLeftTimeout);
    clearInterval(tokenExpireIntervalMinutes);
    clearInterval(tokenExpireIntervalSeconds);
  };

  const startSecondsExpireChecker = () => {
    let timeLeft = updateTimeLeft();
    clearInterval(tokenExpireIntervalSeconds);
    tokenExpireIntervalSeconds = setInterval(() => {
      timeLeft = updateTimeLeft();
      if (timeLeft <= 0) {
        clearInterval(tokenExpireIntervalSeconds);
        dispatch(reduxLogout());
      }
    }, 1000);
  };

  const startMinuteExpireChecker = () => {
    let timeLeft = updateTimeLeft();
    clearInterval(tokenExpireIntervalMinutes);
    tokenExpireIntervalMinutes = setInterval(() => {
      timeLeft = updateTimeLeft();
      if (timeLeft <= 60 * 1000) {
        clearInterval(tokenExpireIntervalMinutes);
      }
    }, 1000 * 60);
  };

  const startTokenExpireChecker = async () => {
    tokenInfo = await authLib.getAccessTokenInfo();
    if (!tokenInfo) {
      dispatch(logout());
      return;
    }
    stopAll();
    const timeLeft = updateTimeLeft();
    tokenExpireGraceTimeout = setTimeout(async () => {
      const newToken = await authLib.refreshAccessToken();
      if (newToken) {
        tokenInfo = await authLib.getAccessTokenInfo();
        stopAll();
        startTokenExpireChecker();
        return;
      }
      startMinuteExpireChecker();
    }, timeLeft - TOKEN_EXPIRE_GRACE_PERIOD);

    tokenExpireOneMinLeftTimeout = setTimeout(() => {
      startSecondsExpireChecker();
    }, timeLeft - TOKEN_EXPIRE_BLOCK_UI_THRESHOLD);
  };

  useEffect(() => {
    if (!user?.id) {
      stopAll();
      dispatch(updateTimeLeftToExpire(null));
      return;
    }
    startTokenExpireChecker();
  }, [user, accessToken]);

  return null;
};

export default TokenExpireChecker;
