// import { addLocaleData } from 'react-intl'
// import locale_en from 'react-intl/locale-data/en'
// import locale_se from 'react-intl/locale-data/se'

import messages_sv from './translations/sv.json'
import messages_en from './translations/en.json'
import messages_nl from './translations/nl.json'

// addLocaleData([...locale_en, ...locale_se])

export const messages = {
    sv: messages_sv,
    en: messages_en,
    nl: messages_nl,
}
