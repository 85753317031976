import { defineMessages } from 'react-intl'

export default defineMessages({
    close: 'Close',
    required: 'It is required',
    serverError: 'Something went wrong at the server',
    cancel: 'Cancel',
    ok: 'OK',
    submitted: 'Submitted',
    not_submitted: 'Not submitted',
    submitted_late: 'Submitted late',
    not_submitted_late: 'Late',
    assessed: 'Assessed',
    assessing: 'Assessing',
    not_assessed: 'Not assessed',
})

