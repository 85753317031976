import React from 'react'
import {Select, Tab} from 'semantic-ui-react'
import {useQuery} from '@apollo/client';
import {gql} from 'apollo-boost'
import {useIntl} from 'react-intl'
import PageLoader from '../PageLoader'
import SchoolAdmins from './SchoolAdmins/'
import messages from './messages';

const ADMIN_SCHOOLS = gql`
    query {
        adminSchools {
            id
            name
            isSuperAdmin
            isAdmin
        }
    }
`

export default function SchoolsAdmin(props) {
  const {
    match: {params},
    history: {push: redirect},
  } = props
  const {formatMessage} = useIntl()

  const {schoolId, subpage: activeIndex = 0} = params
  const {loading: schoolsLoading, error: schoolsError, data = {}} = useQuery(ADMIN_SCHOOLS)
  const {adminSchools = []} = data

  if (schoolsLoading) {
    return <PageLoader/>
  }
  if (schoolsError) {
    return 'error'
  }

  const selectedSchool = adminSchools.find(s => s.id.toString() === schoolId)
  const paneStyle = {
    height: 'calc(100vh - 220px)',
    overflowY: 'auto',
  }
  const panes = []
  if (adminSchools.some(s => s.isSuperAdmin)) {
    panes.unshift({
      menuItem: 'Admins',
      render: () => (
        <Tab.Pane style={paneStyle}>
          {selectedSchool && selectedSchool.isSuperAdmin ? (
            <SchoolAdmins schoolId={schoolId}/>
          ) : (
            <div>You don't have access</div>
          )}
        </Tab.Pane>
      ),
    })
  }
  return (
    <div>
      <div className="ews-school-filter">
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{marginRight: 10}}>{formatMessage({...messages.school})}:</div>
          <Select
            placeholder={formatMessage({...messages.select_school})}
            options={adminSchools.map(s => {
              const sId = s.id.toString()
              return {
                key: sId,
                value: sId,
                text: s.name,
              }
            })}
            onChange={(e, data) => {
              redirect(`/schoolsAdmin/${data.value}`)
            }}
            value={schoolId}
          />
        </div>
      </div>
      <div style={{marginTop: 20}}>
        {schoolId && (
          <Tab
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={(e, {activeIndex}) => redirect(`/schoolsAdmin/${schoolId}/${activeIndex}`)}
          />
        )}
      </div>
    </div>
  )
}
