import ApolloClient, {gql} from 'apollo-boost';
import store from '../redux/store';
import {logout} from '../redux/reducers/auth';
import authLib from '../libs/auth';

export const UPDATE_COURSE_ASSESSMENT = gql`
  mutation($assessments: [CourseAssessmentInput]) {
    updateCourseAssessment(assessments: $assessments) {
      id
    }
  }
`;

export const SET_VIEW_FILTER = gql`
  mutation($view: String!, $hiddenCourses: String!) {
    setFilter(view: $view, hiddenCourses: $hiddenCourses)
  }
`;
export const PUBLISH_EWS_STATUS = gql`
  mutation($studentId: Int!, $courseId: Int!, $published: Boolean!) {
    setEWSStatusVisible(studentId: $studentId, courseId: $courseId, published: $published)
  }
`;

export const UPDATE_EWS_STATUS = gql`
  mutation($status: StudentEWSStatusInput!) {
   updateStudentEWSStatus(ewsStatus: $status){
    id
    created
    modified
    admin_id
    course_definition_id
    student_id
    published
    status
  }
}
`
export const UPDATE_EXTRA_ADJUSTMENTS = gql`
  mutation($adjustments: [Int]!, $courseId: Int!, $studentId: Int!){
    updateStudentExtraAdjustments2(adjustments: $adjustments, courseId: $courseId, studentId: $studentId) {
      name
    }
  }
`;

export const ADD_EWS_COMMENT = gql`
  mutation($comment: String!, $courseId: Int!, $studentId: Int!) {
    addEWSComment(comment: $comment, courseId: $courseId, studentId: $studentId)
  }
`;

export default new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_API || 'http://localhost:4000/graphql',
  request: async (operation) => {
    const accessToken = authLib.getAccessToken();
    console.log('api: ', {query: operation?.query?.loc?.source?.body, variables: operation.variables});
    operation.setContext({
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': '*',
        'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PATCH, PUT',
        'Access-Control-Allow-Headers': '*',
        Authorization: accessToken ? `Token ${accessToken}` : '',
        accessToken,
      },
    });
  },
  onError: ({graphQLErrors, networkError}) => {
    const handlers = [
      {
        code: (c) => c.includes('NO_ACCESS'),
        func: () => {
          authLib.logout();
          store.dispatch(logout());
        },
      },
      {
        code: (c) => c.includes('NO_USER'),
        func: () => {
          authLib.logout();
          store.dispatch(logout());
        },
      }
    ];
    if (graphQLErrors) {
      graphQLErrors.forEach((e) => {
        const handler = handlers.find((h) => h.code(e.message));
        if (handler) {
          handler.func();
        }
      });
    }
  },
});
