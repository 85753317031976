import { defineMessages } from 'react-intl';

export default defineMessages({
  by: 'by',
  takeActionDescription: 'Take action for the student <b>{studentName}</b> for the course <b>{courseName}</b> <i>({courseCode})</i>',
  extraAdjustments: 'Extra adjustments',
  update: 'Update',
  updateFeedback: 'Update feedback',
  status: 'Status',
  approve: 'Approve',
  logs: 'Logs',
  noLogs: 'There aren\'t logs yet',
  added: '<b>added</b> {addedLogs}',
  removed: '<b>removed</b> {removedLogs}',
  changedStatusTo: '<b>changed status</b> to',
  byAdmin: 'by <i>{admin}</i>',
  commentsHeader: 'Comments',
  comment: 'Comment',
  addCommment: 'Add comment',
  studentFeedback: 'Feedback to student',
  studentProgress: 'Student progress',
  actions: 'Actions',
  current_status: 'Current status:',
  published: 'Published',
  not_published: 'Not published',
  closeWithoutSave: 'There are changes not yet save, close anyway?',
  teachers: 'Teachers',
});
