import { defineMessages } from 'react-intl';

export default defineMessages({
  login: 'Login',
  loginHeader: 'Log-in to your account',
  email: 'E-mail address',
  password: 'Password',
  serverProblem: 'There is problem on server',
  signinGoogle: 'Sign in with Google',
  loginErrorTitle: 'Error logging in.',
  loginError: 'Try again and contact your administrator if the problem perists.',
  loginErrorCookies: 'It looks like you are blocking cookies, Insights needs them to function.',
  email_required: 'Email is required',
  password_required: 'Password is required',
  back: 'Back',
  no_google_account: 'No Google Classroom login?',
  click_for_email_login: 'Click here to use your StudyBee account!',
  no_studybee_account: 'No account? Talk to the schools administrator',
  login_welcome: 'Log into StudyBee Insights for a clear overview and a full overview or your students progress and challenges.',
  read_more: 'Is your school not using StudyBee yet? Read more on ',
  tokenExpireMessage: 'Your session will expire in __time__ __time_unit__, login here to avoid loosing any unsaved changes',
  minutes: 'minutes',
  seconds: 'seconds',
  not_verified_teacher_error: 'Your user is not a verified teacher, please contact your Google Workspace admin.',
});
