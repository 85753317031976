import React from 'react';
import {useSpring, animated} from 'react-spring';
import {useIntl} from 'react-intl';
import LegendItem from './legendItem';
import {GradeStatus, SmileyStatus} from '../StudentTable/status';
import ExtraAdjustmentIcon from '../StudentTable/status/extraAdjustmentIcon';
import {colors, fonts} from '../../../theme';
import closeIcon from '../../../assets/close.svg';
import expandIcon from '../../../assets/expand.svg';
import messages from './messages';
import eye from '../../../assets/hide.svg';
import hiddenEye from '../../../assets/hidden.svg';

const {version} = require('../../../../package.json');

const Legend = (props) => {
  const {close, showSplash} = props;
  const {formatMessage} = useIntl();
  const [animProp, set] = useSpring(() => ({
    marginRight: 0,
    from: {marginRight: -400},
    config: {mass: 1, velocity: 0, friction: 26},
    onRest: (props) => {
      if (props.value.marginRight === -400) {
        close();
      }
    },
  }));

  return (
    <animated.div
      style={{
        ...animProp,
        position: 'fixed',
        right: 0,
        top: 0,
        bottom: 0,
        width: '400px',
        boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
        backgroundColor: colors.white,
        overflow: 'auto',
        zIndex: 2000,
      }}
    >
      <div style={{position: 'relative'}}>
        <div
          style={{
            display: 'flex',
            padding: '20px 20px 20px 20px',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${colors.borderColor}`,
          }}
        >
          <p style={{...fonts.LARGE, fontWeight: 800}}>
            {formatMessage({...messages.instructions})}
          </p>
          <img
            alt={'X'}
            src={closeIcon}
            onClick={() => set({marginRight: -400})}
            style={{
              padding: '7px',
              width: '30px',
              height: '30px',
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
            padding: '20px',
            borderBottom: `1px solid ${colors.borderColor}`,
          }}
        >
          <span
            onClick={() => showSplash()}
            style={{
              width: 'fit-content',
              padding: '8px',
              borderRadius: '5px',
              ...fonts.MEDIUM,
              fontWeight: 800,
            }}
          >
            {formatMessage({...messages.view_intro})} >
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            padding: '20px 20px 20px 20px',
            justifyContent: 'space-between',
          }}
        >
          <p style={{...fonts.LARGE, fontWeight: 800}}>
            {formatMessage({...messages.symbol_explanation})}
          </p>
        </div>

        <div
          style={{display: 'flex', backgroundColor: colors.white, padding: '8px 20px 8px 20px'}}
        >
          <img src={eye} style={{width: '28px', height: '28px', padding: '4px'}}/>
          <p style={{...fonts.MEDIUM, marginLeft: '20px'}}>
            {formatMessage({...messages.eye_hide})}
          </p>
        </div>
        <div
          style={{display: 'flex', backgroundColor: colors.white, padding: '8px 20px 8px 20px'}}
        >
          <img src={hiddenEye} style={{width: '28px', height: '28px', padding: '4px'}}/>
          <p style={{...fonts.MEDIUM, marginLeft: '20px'}}>
            {formatMessage({...messages.eye_unhide})}
          </p>
        </div>
        <div
          style={{display: 'flex', backgroundColor: colors.grey, padding: '8px 20px 8px 20px'}}
        >
          <img alt={'->'} src={expandIcon} style={{transform: 'rotate(-180deg)'}}/>
          <p style={{...fonts.MEDIUM, fontWeight: '800', marginLeft: '20px'}}>
            {formatMessage({...messages.in_collapsed_mode})}
          </p>
        </div>
        <div style={{padding: '20px'}}>
          <LegendItem
            extraAdjustments={0}
            comments={false}
            grade={''}
            background={colors.stripedGreenBg}
            backgroundColor={false}
            description={formatMessage({...messages.legend_text_striped_status})}
          />
          <LegendItem
            extraAdjustments={0}
            comments={false}
            grade={''}
            // background={colors.stripedGreenBg}
            backgroundColor={colors.green}
            description={formatMessage({...messages.legend_text_solid_status})}
          />
          <LegendItem
            extraAdjustments={3}
            comments={false}
            grade={''}
            // background={colors.stripedGreenBg}
            backgroundColor={colors.green}
            description={formatMessage({...messages.legend_text_extra_adjustments_small})}
          />
          <LegendItem
            extraAdjustments={0}
            comments={true}
            grade={''}
            // background={colors.stripedGreenBg}
            backgroundColor={colors.green}
            description={formatMessage({...messages.legend_text_feedback})}
          />
          <LegendItem
            extraAdjustments={0}
            comments={false}
            grade={'B'}
            // background={colors.stripedGreenBg}
            backgroundColor={colors.green}
            description={formatMessage({...messages.legend_text_grade_small})}
          />
          <LegendItem
            extraAdjustments={0}
            comments={false}
            grade={''}
            // background={colors.stripedGreenBg}
            backgroundColor={colors.grey}
            description={formatMessage({...messages.legend_text_empty})}
          />
          <LegendItem
            extraAdjustments={0}
            comments={false}
            grade={''}
            // background={colors.stripedGreenBg}
            backgroundColor={colors.borderColorDark}
            description={formatMessage({...messages.legend_text_dark_status})}
          />
        </div>
        <div
          style={{display: 'flex', backgroundColor: colors.grey, padding: '8px 20px 8px 20px'}}
        >
          <img alt={'<-'} src={expandIcon} style={{}}/>
          <p style={{...fonts.MEDIUM, fontWeight: '800', marginLeft: '20px'}}>
            {formatMessage({...messages.in_expanded_mode})}
          </p>
        </div>
        <div style={{padding: '20px'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <SmileyStatus
              status={'happy'}
              setByAdmin
              hideBottomBorder
              backgroundColor={colors.grey}
            />
            <p style={{...fonts.MEDIUM, marginLeft: '20px', width: '300px'}}>
              {formatMessage({...messages.legend_text_solid_smiley})}
            </p>
          </div>
          <div style={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
            <SmileyStatus
              status={'happy'}
              setByAdmin={false}
              hideBottomBorder
              backgroundColor={colors.grey}
            />
            <p style={{...fonts.MEDIUM, marginLeft: '20px', width: '300px'}}>
              {formatMessage({...messages.legend_text_striped_smiley})}
            </p>
          </div>
          <div style={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
            <GradeStatus grade={'A'} hideBottomBorder backgroundColor={colors.grey}/>
            <p style={{...fonts.MEDIUM, marginLeft: '20px', width: '300px'}}>
              {formatMessage({...messages.legend_text_grade})}
            </p>
          </div>
          <div style={{display: 'flex', alignItems: 'center', marginTop: '15px'}}>
            <div
              style={{
                backgroundColor: colors.grey,
                width: '38px',
                height: '47px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ExtraAdjustmentIcon extraAdjustments={3}/>
            </div>
            <p style={{...fonts.MEDIUM, marginLeft: '20px', width: '300px'}}>
              {formatMessage({...messages.legend_text_extra_adjustments})}
            </p>
          </div>
        </div>
        <span
          style={{
            position: 'absolute',
            right: '10px',
            bottom: '0px',
            fontSize: 10,
            color: '#999',
          }}
        >
          {version}
        </span>
      </div>
    </animated.div>
  );
};

export default Legend;
