import store from '../store'
const isDebugMode = () => {
    return process.env.REACT_APP_DEBUG || store.get('debug')
}
function getStackTrace() {
    let stack

    try {
        throw new Error('')
    } catch (error) {
        stack = error.stack || ''
    }

    stack = stack.split('\n').map(function(line) {
        return line.trim()
    })
    const stackInArray = stack.splice(stack[0] === 'Error' ? 2 : 1)
    const callerString = stackInArray[1] || ''
    const callerName = callerString.slice(0, callerString.indexOf('('))
    return callerName
}
export default {
    info: (...args) => {
        if (isDebugMode) {
            console.log.apply(null, [getStackTrace(), ...args])
        }
    },
    error: (...args) => {
        if (isDebugMode) {
            console.error.apply(null, [...args])
        }
    },
}
