import { defineMessages } from 'react-intl';

export default defineMessages({
  welcome_title: 'Welcome to StudyBee!',
  welcome_message: 'Before you start we need to know a couple of things.',
  country_select_hint: 'Important for us to know so that we can handle your data safe according to the law within your country.',
  choose_country: 'Choose country',
  continue: 'CONTINUE',
  back: 'BACK',
  check_domain_title_text: 'Does your school have a personal data assistant agreement with StudyBee?',
  check_domain_main_text: 'StudyBee cares for your and your students\' data. Therefore, we need to know if your school has a personal data assistant agreement with us before you can use StudyBee.',
  check_domain_main_text2: 'We will check if your school has registered the following domain from your email:',
  check_domain_button: 'CHECK NOW',
  check_domain_fail: 'We are sorry but it seems like your school does not have a personal data assistant agreement with StudyBee.',
  check_domain_success: 'Congratulations! Your school has a personal data assistant agreement with StudyBee.',
  select_school_title: 'Where do you teach?',
  select_school_hint: 'School',
  accept_tou_text: 'I have read and accept the terms of use.',
  accept_tou_button: 'I ACCEPT',
  verified_teacher_error: 'An error occurred, please check that you user is a verified teacher in your Google Workspace and try again.',
});
