import { Button } from 'semantic-ui-react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { fonts } from '../../theme';
import request from '../../libs/request';
import messages from './messages';

const CheckDomain = (props) => {
  const { onBack, onDone, user } = props;
  const { formatMessage } = useIntl();
  const [domainOk, setDomainOk] = useState('not_checked');

  const getUserInfo = async () => {
    const userInfo = (await request.get('/users/user_info/')).data;
    if (userInfo.whitelisted_domain) {
      setDomainOk('OK');
    } else {
      setDomainOk('NOK');
    }
  };

  const buttonClick = () => {
    if (domainOk === 'OK') {
      onDone();
    } else {
      getUserInfo();
    }
  };

  let buttonText = formatMessage({ ...messages.check_domain_button });
  let progressText;
  if (domainOk === 'OK') {
    buttonText = formatMessage({ ...messages.continue });
    progressText = formatMessage({ ...messages.check_domain_success });
  } else if (domainOk === 'NOK') {
    progressText = formatMessage({ ...messages.check_domain_fail });
  }


  return (
    <div style={{ width: '400px' }}>
      <p style={{ ...fonts.LARGE }}>{formatMessage({ ...messages.check_domain_title_text })}</p>
      <p style={{ ...fonts.MEDIUM }}>{formatMessage({ ...messages.check_domain_main_text })}</p>
      <p style={{ ...fonts.MEDIUM, fontWeight: '800' }}>{formatMessage({ ...messages.check_domain_main_text2 })}</p>
      <p
        style={{
          ...fonts.MEDIUM,
          fontWeight: '800',
          backgroundColor: '#FFFFFF80',
          padding: '10px',
        }}
      >
        {user.emailAddress.split('@')[1]}
      </p>
      {progressText && <p style={{ ...fonts.MEDIUM, fontWeight: '800' }}>{progressText}</p> }
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
        <Button color={'yellow'} onClick={onBack}>
          {formatMessage({ ...messages.back })}
        </Button>
        <Button disabled={domainOk === 'NOK'} color={'yellow'} onClick={buttonClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default CheckDomain;
