import store from '../store';
import reduxStore from '../../redux/store';
import { updateAccessToken } from '../../redux/reducers/auth';

const { OAuth2Client } = require('google-auth-library');

const oAuth2Client = new OAuth2Client(process.env.REACT_APP_CLIENT_ID);

async function storeToken({ accessToken }) {
  store.set('accessToken', accessToken);
  reduxStore.dispatch(updateAccessToken(accessToken));
}

function storeEmailToken({ token }) {
  store.set('emailToken', token);
}

function storeUser({ user }) {
  store.set('user', JSON.stringify(user));
}

function getUser() {
  const user = store.get('user');
  return JSON.parse(user);
}

async function refreshAccessToken() {
  let accessToken = store.get('accessToken');
  if (!accessToken) {
    const token = store.get('emailToken');
    return token;
  }

  try {
    const res = await (
      await fetch(process.env.REACT_APP_GRAPHQL_API || 'http://localhost:4000/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Token ${accessToken}`,
        },
        body: JSON.stringify({ query: 'query {refreshAccessToken}' }),
      })
    ).json();
    accessToken = res?.data?.refreshAccessToken;
  } catch (e) {
    console.log('could not refresh accessToken');
  }
  storeToken({ accessToken });
  return accessToken;
}

async function getAccessTokenInfo() {
  let info;
  const accessToken = store.get('accessToken');
  if (!accessToken) {
    return info;
  }

  try {
    info = await oAuth2Client.getTokenInfo(accessToken);
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return info;
}

function getAccessToken() {
  const accessToken = store.get('accessToken');
  if (!accessToken) {
    return store.get('emailToken');
  }
  return accessToken;
}

function logout() {
  store.set('accessToken', '');
  store.set('idToken', '');
  store.set('user', '{ "wasLoggedOut": true }');
}

export default {
  storeEmailToken,
  storeToken,
  storeUser,
  logout,
  getAccessToken,
  refreshAccessToken,
  getAccessTokenInfo,
  getUser,
};
