import { defineMessages } from 'react-intl'

export default defineMessages({
    show: 'Show',
    school: 'School',
    admin: 'Admin',
    filterAllStatuses: 'all statuses',
    byAdmin: 'by admin',
    bySystem: 'by system',
    logout: 'Logout',
    asTeacher: 'As a teacher',
    asMentor: 'As a mentor',
    filterEmpty: 'Select classroom or student',
    showNoCurriculums: 'Show classes without curriculums',
    showHiddenCourses: 'Show hidden courses',
    showArchivedCourses: 'Show archived courses',
    show_legend: 'Show legend',
    select_many: 'Select many',
    select_school: 'Select school',
    no_school_selected: 'Select a school.',
    no_filter_selected: 'Select a filter.',
    school_filter_explain: 'After a school and filter is selected the overview is shown here',
})
