import React from 'react';
import { Button, Popup, PopupContent } from 'semantic-ui-react';
import { colors, fonts } from '../../../../theme';
import msgIcon from '../../../../assets/msg-icon.png';
import { useIntl } from 'react-intl';
import messages from '../messages';

export default function GradeStatus(props) {
  const { grade, feedback, openStudentProgress, hideBottomBorder, backgroundColor } = props;
  const label = grade;
  const { formatMessage } = useIntl();

  return (
    <div
      style={{
        width: '38px',
        height: '47px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: `${hideBottomBorder?0:1}px solid ${colors.borderColorDark}`,
        backgroundColor,
        cursor:'pointer',
      }}
    >
      {feedback && (
        <Popup
          on={'click'}
          basic
          trigger={
            <div style={{ height: '100%', width: '100%', position:'relative', display:'flex', justifyContent:'center', alignItems:'center' }}>

              <img
                alt="!"
                src={msgIcon}
                style={{ position: 'absolute', top: '2px', right: '2px', width: '15px', height: '15px' }}
              />
              <p
                style={{
                  ...fonts.LARGE,
                  fontWeight: '800',
                  color: '#000',
                  opacity: label ? 1 : 0.2,
                }}
              >
                {label || '-'}
              </p>
            </div>
          }
        >
          <PopupContent>
            <div style={{maxWidth:'400px'}}>
              <p
                style={{
                  ...fonts.MEDIUM,
                  // width: '400px',
                  maxWidth:'400px',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 8,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {feedback}
              </p>
              <Button content={formatMessage({...messages.read_more})} onClick={openStudentProgress}/>
            </div>
          </PopupContent>
        </Popup>
      )}
      {!feedback && (
        <p
          onClick={openStudentProgress}
          style={{
            ...fonts.LARGE,
            fontWeight: '800',
            color: '#000',
            opacity: label ? 1 : 0.2,
          }}
        >
          {label || '-'}
        </p>
      )}

    </div>
  );
}
