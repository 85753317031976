import { fonts } from '../../../theme';
import expandIcon from '../../../assets/expand.svg';
import hideIcon from '../../../assets/hide.svg';
import hiddenIcon from '../../../assets/hidden.svg';
import archiveIcon from '../../../assets/archived.svg';
import { colors } from '../../../theme';
import React, { useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

export default function StudentCourseHeaderCollapsed(props) {
  const {
    setExpanded,
    expanded,
    course,
    selectMode,
    setSelected,
    isSelected,
    highlight,
    toggleHide,
    schoolId,
    isHidden: filterHidden = false,
  } = props;
  const { name, archived, classroomId, classroomName } = course;
  const [showHide, setShowHide] = useState(filterHidden);
  if (expanded) {
    return null;
  }
  const width = selectMode ? '170px' : '190px';
  const color = filterHidden ? colors.borderColorDark : colors.textColor;
  return (
    <div
      style={{ ...style.container, backgroundColor: highlight ? colors.inactive : colors.white }}
      onMouseOver={() => setShowHide(true)}
      onMouseLeave={() => setShowHide(!!filterHidden)}
    >
      <div style={{ ...style.textContainer, width: width }}>
        {archived && <img src={archiveIcon} style={{width:'24px', height:'24px', position:'absolute', top:'6px'}}/>}
        <p style={{ ...style.courseName, width, color, left:archived?'44px':'15px' }}>{name}</p>
        <NavLink to={`/ews/${schoolId}/classes/${classroomId}`}>
          <p style={{ ...style.classroomName, width, color, left:archived?'44px':'15px' }}>{classroomName}</p>
        </NavLink>
      </div>
      {showHide && (
        <div
          style={{
            ...style.iconContainer,
            position: 'absolute',
            width: '42px',
            height: '38px',
            left: `${250 - 41 * 2 - (selectMode ? 20 : 0)}px`,
            backgroundColor: highlight ? colors.inactive : colors.white,
            borderRight: `1px solid ${colors.borderColor}`,
            borderBottom: `1px solid ${colors.borderColor}`,
            zIndex: 100,
          }}
        >
          <div onClick={() => toggleHide(course)}>
            <img
              alt={'X'}
              src={filterHidden ? hiddenIcon : hideIcon}
              style={{ width: '16px', height: '16px', transform: 'rotate(90deg)' }}
            />
          </div>
        </div>
      )}

      <div style={{ ...style.iconContainer, width: selectMode ? '60px' : '41px' }}>
        <div onClick={() => setExpanded(!expanded)}>
          <img alt={'->'} src={expandIcon} style={{ transform: 'rotate(90deg)' }} />
        </div>
        {selectMode && (
          <Checkbox
            checked={isSelected}
            style={{ marginLeft: '10px', transform: 'rotate(90deg)' }}
            onChange={setSelected}
          />
        )}
      </div>
      {archived && <div style={{backgroundColor:colors.greyOpacity, position:'absolute', width:selectMode?'190px':'210px', height:'38px', border:'0px solid', top:0, left:0}}/>}
    </div>
  );
}

const style = {
  container: {
    width: '250px',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.borderColor}`,
    height: '38px',
    position:'relative',
  },
  textContainer: {
    border: '0px solid blue',
    padding: '0px 0px 0px 15px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  courseName: {
    top: '5px',
    position: 'absolute',
    border: '0px solid red',
    ...fonts.SMALL,
    lineHeight: '14px',
    fontWeight: '800',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  classroomName: {
    bottom: '5px',
    position: 'absolute',
    border: '0px solid green',
    ...fonts.SMALL,
    lineHeight: '14px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  iconContainer: {
    cursor: 'pointer',
    borderLeft: `1px solid ${colors.borderColor}`,
    borderRight: `1px solid ${colors.borderColor}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
