import {colors, fonts} from '../../../../theme';
import React from 'react';
import ExtraAdjustmentIcon from './extraAdjustmentIcon';
import {Checkbox} from 'semantic-ui-react';
import msgIcon from '../../../../assets/msg-icon.png';
import {newestStatus, STATUS_TO_SCORE} from "../../utils";


export default function CombinedStatus(props) {
  const {
    status,
    expanded,
    grade,
    extraAdjustments,
    click,
    onSelect,
    selected,
    selectMode,
    comment,
    onHover,
    studentId,
    courseId,
  } = props;

  const hover = () => {
    onHover({studentId, courseId});
  };

  const select = () => {
    onSelect(studentId);
  }

  let color = (() => {
    switch (status?.adminStatus) {
      case '10':
        return colors.green;
      case '20':
        return colors.primary;
      case '30':
        return colors.red;
      default:
        return colors.white;
    }
  })();

  let background = (() => {
    const adminStatusScore = status?.adminStatus ? STATUS_TO_SCORE[status.adminStatus] : 0;
    const systemStatusScore = status?.systemStatus? STATUS_TO_SCORE[status.systemStatus]: 0;
    if (newestStatus(status) === 'admin') {
      return null;
    }
    if (adminStatusScore >= systemStatusScore) {
      return null;
    }
    switch (status.systemStatus) {
      case '10':
        return colors.stripedGreenBg;
      case '20':
        return colors.stripedYellowBg;
      case '30':
        return colors.stripedRedBg;
    }
  })();

  const hasExtraAdjustmentsOrGrade = extraAdjustments > 0 || !!grade?.label;
  return (
    <div
      onMouseOver={hover}
      style={{
        width: '38px',
        display: 'flex',
        alignItems: 'flex-start',
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      <div
        onClick={click}
        style={{
          width: '38px',
          height: '47px',
          borderRight: `${expanded ? 0 : 1}px solid ${colors.borderColorDark}`,
          borderBottom: `1px solid ${colors.borderColorDark}`,
          background: background,
          backgroundColor: color,
        }}
      />
      {!expanded && (
        <div
          onClick={click}
          style={{
            position: 'absolute',
            height: '47px',
            width: `37px`,
            display: 'flex',
            alignItems: 'flex-end',
            padding: '5px 5px 0px 5px',
            flexDirection: 'column',
          }}
        >
          {extraAdjustments > 0 && (
            <div style={{right: '3px', position: 'absolute'}}>
              <ExtraAdjustmentIcon {...{extraAdjustments, small: true}} />
            </div>
          )}
          {comment &&
          <img src={msgIcon} style={{height: '12px', width: '12px', position: 'absolute', top: '17px', right: '2px'}}/>
          }
          <p
            style={{
              ...fonts.SMALL,
              fontWeight: '800',
              position: 'absolute',
              bottom: '2px',
              color: '#000',
            }}
          >
            {grade}
          </p>
        </div>
      )}
      {selectMode && (
        <Checkbox
          checked={selected}
          onChange={select}
          style={{
            position: 'absolute',
            top: '14px',
            left: !hasExtraAdjustmentsOrGrade || expanded ? '2px' : '2px',
          }}
        />
      )}
    </div>
  );
}
