import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/client';
import { colors, fonts } from '../../theme';
import GoogleLoginButton from './googleLoginButton';
import { useWindowDimensions } from '../../libs/hooks';
import messages from './messages';
import authLib from '../../libs/auth';

const GET_ACCESS_TOKEN = gql`
  query($code: String!) {
    getAccessToken(code: $code)
  }
`;

const LoginSplash = (props) => {
  const { blockUi } = props;
  const timeLeftToExpire = useSelector((state) => state.auth.timeLeftToExpire);
  const dimensions = useWindowDimensions();
  const { formatMessage } = useIntl();

  const [getAccessToken] = useLazyQuery(GET_ACCESS_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted: async ({ getAccessToken: accessToken }) => {
      authLib.storeToken({
        accessToken,
      });
      // this will trigger token info to be updated
      authLib.getAccessToken();
    },
  });

  const loginWithCode = (code) => {
    getAccessToken({
      variables: {
        code,
      },
    });
  };

  const date = new Date(timeLeftToExpire);
  const m = date.getMinutes();
  const s = date.getSeconds();

  let loginText = formatMessage({ ...messages.tokenExpireMessage });
  const minutes = formatMessage({ ...messages.minutes });
  const seconds = formatMessage({ ...messages.seconds });
  loginText = loginText.replace('__time__', m > 0 ? m + 1 : s);
  loginText = loginText.replace('__time_unit__', m > 0 ? minutes : seconds);
  return (
    <div
      style={{
        right: blockUi ? dimensions.width / 2 - 150 : '20px',
        bottom: blockUi ? dimensions.height / 2 - 80 : '20px',
        height: '160px',
        position: 'fixed',
        zIndex: 100000,
        width: '340px',
        borderRadius: '5px',
        border: `2px solid ${colors.borderDark}`,
        backgroundColor: colors.white,
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ ...fonts.MEDIUM, marginRight: '20px', marginBottom: '20px' }}>
              {loginText}
            </span>
      </div>
      <GoogleLoginButton response={(r) => loginWithCode(r.code)} />
    </div>
  );
};

export default LoginSplash;
