import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { ApolloProvider } from '@apollo/client';
import store from './redux/store';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import client from './client';

firebase.initializeApp({
  apiKey: 'AIzaSyDuAC_ajXrOTAMo2ngtGDTnfhr7-GbGHr4',
  authDomain: 'studybeereact.firebaseapp.com',
  databaseURL: 'https://studybeereact.firebaseio.com',
  projectId: 'studybeereact',
  storageBucket: 'studybeereact.appspot.com',
  messagingSenderId: '804858914282',
  appId: '1:804858914282:web:e535bafa513764681dbe93',
});


firebase.analytics();

// const DEBUG = process.env.REACT_APP_DEBUG || false

// add date querystring in order to disable browser cache
if (!window.location.href.includes('date=')) {
  const newHref = `${window.location.origin}/?date=${new Date().getTime()}${window.location.hash}`;
  window.location.replace(newHref);
} else {
  const AppContainer = () => (
    <HttpsRedirect>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </Provider>
    </HttpsRedirect>
  );
  ReactDOM.render(<AppContainer />, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
