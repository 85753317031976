import _ from 'lodash';
import moment from 'moment';
import { colors } from '../../theme';

export const GREEN = 'GREEN';
export const YELLOW = 'YELLOW';
export const RED = 'RED';

export const STATUS_TO_SCORE = {
  '10': 1,
  '20': 2,
  '30': 3
};

export const newestStatus = (status) => {
  if(!status){
    return null;
  }
  if (!status.adminStatus) {
    return 'system';
  }
  if (!status.systemStatus) {
    return 'admin';
  }
  if (new Date(status.adminStatusModified) > new Date(status.systemStatusModified)) {
    return 'admin'
  } else {
    return 'system';
  }
};

export const STATUS_BY_CODE = {
  '10': GREEN,
  '20': YELLOW,
  '30': RED,
};
export const statusToScore = (status) => {
  if (!status) {
    return 0;
  }
  switch (status) {
    case 'WHITE':
      return 0;
    case 'happy':
    case 'GREEN':
      return 1;
    case 'medium':
    case 'YELLOW':
      return 2;
    case 'sad':
    case 'RED':
      return 3;
    default:
      return 0;
  }
};
export const scoreToColor = (score) => {
  switch (score) {
  case 0:
    return colors.white;
  case 1:
    return colors.green;
  case 2:
    return colors.primary;
  case 3:
    return colors.red;
  default:
    return 0;
  }
};

export const getDominantStatus = (meta, EWSStatus) => {
  const metaScore = statusToScore(meta.status);
  const EWSScore = statusToScore(EWSStatus.status);

  let combinedStatus = EWSScore > metaScore ? EWSScore : metaScore;
  const metaIsNewest = new Date(EWSStatus.modified) < new Date(meta.modified);
  if (metaIsNewest) {
    combinedStatus = metaScore;
  }
  if ((meta.isStatusByAdmin && metaScore >= EWSScore) || metaIsNewest) {
    return {status: combinedStatus, isEWS: false};
  }

  return {status: combinedStatus, isEWS: true};

};

export const statusToAPIStatus = (status) => {
  if (!status) {
    return null;
  }
  switch (status.toLowerCase()) {
    case 'white':
      return null;
    case 'happy':
    case 'green':
      return '10';
    case 'medium':
    case 'yellow':
      return '20';
    case 'sad':
    case 'red':
      return '30';
    default:
      return null;
  }
};

export const STATUS_BY_COLOR = _.invert(STATUS_BY_CODE);

export const splitter = '-----';
export const getCellId = ({ courseId, studentId }) => {
  return `${courseId}${splitter}${studentId}`;
};

export const getIdsFromCellId = (cellId) => {
  const [courseId, studentId] = cellId.split(splitter);
  return {
    courseId,
    studentId,
  };
};

export const getStatusFromLogs = (logs) => {
  const numberOfAdded = logs.filter((log) => log.status === 'ADDED');
  const numberOfRemoved = logs.filter((log) => log.status === 'REMOVED');
  return numberOfAdded.length - numberOfRemoved.length;
};

export const getLogsByExtraAdjustment = (extraAdjustmentsLogs = []) => {
  return extraAdjustmentsLogs.reduce((acc, extraAdjustmentsLog) => {
    const { extra_adjustment_id: extraAdjustmentId } = extraAdjustmentsLog;
    if (!extraAdjustmentId) {
      return acc;
    }

    if (!acc[extraAdjustmentId]) {
      acc[extraAdjustmentId] = [];
    }

    if (extraAdjustmentsLog.status) {
      acc[extraAdjustmentId].push({status: extraAdjustmentsLog.status, name: extraAdjustmentsLog.name});
    }

    return acc;
  }, {});
};

export const getCurrentExtraAdjustments = ( extraAdjustmentsLogs ) => {
  const currentExtraAdjustments = extraAdjustmentsLogs.sort((a,b)=> new Date(a.created) - new Date(b.created)).reduce((acc, e) => {
    if(e.status === 'ADDED' && !acc.find(a => a.extra_adjustment_id === e.extra_adjustment_id)){
      acc.push(e);
    }
    if(e.status === 'REMOVED'){
      acc = acc.filter((a) => a.extra_adjustment_id !== e.extra_adjustment_id);
    }
    return acc;
  },[]);
  return currentExtraAdjustments.map(c => c.extra_adjustment_id);
};

export const getLogs = ({earlyWarningSystemStatusLogs, earlyWarningSystemExtraAdjustmentLogs, earlyWarningSystemCommentLogs}) => {
  const addDay = (date) => ({
    ...date,
    day: moment(date.created).format('YYYY-MM-DD'),
  });
  const convertToObj = (acc, date) => {
    if (!acc[date.day]) {
      acc[date.day] = [];
    }

    acc[date.day].push(date);
    return acc;
  };

  const statusLogs = earlyWarningSystemStatusLogs.map(addDay);
  const earlyWarningSystemStatusLogsByDay = statusLogs.reduce(convertToObj, {});

  const adjustmentLogs = earlyWarningSystemExtraAdjustmentLogs.map(addDay);
  const groupOfExtraAdjustmentsByDay = adjustmentLogs.reduce(
    convertToObj,
    {},
  );

  const commentLogs = earlyWarningSystemCommentLogs.map(addDay);
  const earlyWarningSystemCommentLogsByDay = commentLogs.reduce(convertToObj, {});

  const logDays = (() => {
    const days = [
      ...Object.keys(groupOfExtraAdjustmentsByDay || {}),
      ...Object.keys(earlyWarningSystemStatusLogsByDay || {}),
      ...Object.keys(earlyWarningSystemCommentLogsByDay || {}),
    ];
    return _.uniq(days).sort(function compare(a, b) {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateB - dateA;
    });
  })();

  let previousStatus = undefined;
  let logsByDay = {};
  [...logDays].reverse().forEach((day) => {
    const logs = (() => {
      const logsByExtraAdjustment = getLogsByExtraAdjustment(groupOfExtraAdjustmentsByDay[day]);
      const extraAdjustmentsLogs = Object.keys(logsByExtraAdjustment).map((id) => {
        const status = getStatusFromLogs(logsByExtraAdjustment[id]);
        return {
          status,
          isExtraAdjustmentLogs: true,
          id: parseInt(id, 10),
          name: logsByExtraAdjustment[id][0].name,
        };
      });

      const statusLogs = (() => {
        if (!earlyWarningSystemStatusLogsByDay[day]) {
          return [];
        }
        earlyWarningSystemStatusLogsByDay[day].sort(function compare(a, b) {
          const dateA = new Date(a.created);
          const dateB = new Date(b.created);
          return dateB - dateA;
        });

        const status = (() => {
          const statusCode = _.get(earlyWarningSystemStatusLogsByDay, `${day}[0].status`);
          if (!statusCode || !STATUS_BY_CODE[statusCode]) {
            return null;
          }

          return STATUS_BY_CODE[statusCode];
        })();
        return [
          {
            status,
            isStatusLog: true,
          },
        ];
      })();
      return [...extraAdjustmentsLogs, ...statusLogs];
    })();
    const admins = (() => {
      const getAdmin = (log) => ({fullName: log.adminFullName, email: log.adminEmail, photo_url: log.adminPhotoUrl});
      return _.uniqBy(
        [
          ..._.get(groupOfExtraAdjustmentsByDay, `${day}`, []).map(getAdmin),
          ..._.get(earlyWarningSystemStatusLogsByDay, `${day}`, []).map(getAdmin),
        ],
        'id',
      );
    })();
    const status = (() => {
      const statusCode = _.get(earlyWarningSystemStatusLogsByDay, `${day}[0].status`);
      const statusColor = _.get(STATUS_BY_CODE, statusCode);
      if (!statusColor) {
        return previousStatus;
      }

      previousStatus = statusColor;
      return statusColor;
    })();

    const comments = _.get(earlyWarningSystemCommentLogsByDay, day, [])
      .filter((commentLog) => commentLog.comment)
      .map((commentLog) => {
        return {
          comment: commentLog.comment,
          admin: {fullName: commentLog.adminFullName, email: commentLog.adminEmail, photo_url: commentLog.adminPhotoUrl},
          created: commentLog.created,
          id: commentLog.id,
        };
      });
    logsByDay[day] = {
      admins,
      status,
      logs,
      comments,
    };
  });

  return {
    logDays,
    logsByDay,
  };
};
