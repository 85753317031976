import { fonts } from '../../../theme';
import ExtraAdjustmentIcon from '../StudentTable/status/extraAdjustmentIcon';
import msgIcon from '../../../assets/msg-icon.png';
import React from 'react';

const DummyStatus = (props) => {
  const {extraAdjustments, comments, grade, background, backgroundColor } = props
  return (
    <div
      style={{
        width: '39px',
        display: 'flex',
        alignItems: 'flex-start',
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '39px',
          height: '47px',
          background: background,
          backgroundColor,
        }}
      />
        <div
          style={{
            position: 'absolute',
            height: '47px',
            width: `38px`,
            display: 'flex',
            alignItems: 'flex-end',
            padding: '5px 5px 0px 5px',
            flexDirection: 'column',
          }}
        >
          {extraAdjustments > 0 && (
            <div style={{ right: '3px', position: 'absolute' }}>
              <ExtraAdjustmentIcon {...{ extraAdjustments, small: true }} />
            </div>
          )}
          {comments &&
          <img src={msgIcon} style={{height:'12px', width:'12px', position:'absolute', top: '17px', right: '2px'}}/>
          }
          <p
            style={{
              ...fonts.SMALL,
              fontWeight: '800',
              position: 'absolute',
              bottom: '2px',
              color: '#000',
            }}
          >
            {grade}
          </p>
        </div>
    </div>
  );
};

export default DummyStatus;
