import { colors } from './index';

const dialogStyle = {
  contentStyle: {
    background: colors.white,
    padding: '20px',
    borderRadius: '8px',
    border: `1px solid ${colors.borderColor}`,
    boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
  },
  overlayStyle: { background: 'transparent', border: `1px solid ${colors.borderColor}` },
  arrowStyle: { color: colors.white, },
};

export default { dialogStyle };
