import {colors} from "../../theme";
import {Checkbox} from "semantic-ui-react";
import messages from "./EWSComponent/messages";
import React, {useState} from "react";
import {useIntl} from "react-intl";

const EWSCheckBoxes = ({numberOfHiddenCourses=0, numberOfArchivedCourses=0, onShowArchived, selectMode, setSelectMode, onShowHidden, showHidden}) => {
  const [showArchivedCourses, setShowArchivedCourses] = useState();
  const [showNoCurriculum, setShowNoCurriculum] = useState();
  const {formatMessage} = useIntl();
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginBottom: '10px',
        position: 'relative',
      }}
    >
      <div
        style={{
          marginRight: '10px',
          border: `1px solid${colors.borderColor}`,
          borderRadius: '20px',
          padding: '10px 20px 10px 20px',
          boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
        }}
      >
        <Checkbox
          checked={selectMode}
          label={formatMessage({...messages.select_many})}
          style={{marginRight: '10px'}}
          onChange={() => setSelectMode(!selectMode)}
        />
        <Checkbox
          checked={showHidden}
          onChange={() => onShowHidden(!showHidden)}
          style={{marginRight: '10px'}}
          label={`${formatMessage({...messages.showHiddenCourses})}(${numberOfHiddenCourses})`}
        />
        <Checkbox
          checked={showArchivedCourses}
          onChange={() => {
            setShowArchivedCourses(!showArchivedCourses);
            onShowArchived(!showArchivedCourses);
          }}
          style={{marginRight: '10px'}}
          label={`${formatMessage({...messages.showArchivedCourses})}(${numberOfArchivedCourses})`}
        />
        {/*<Checkbox*/}
        {/*  style={{marginRight: '10px'}}*/}
        {/*  onChange={() => setShowNoCurriculum(!showNoCurriculum)}*/}
        {/*  label={formatMessage({...messages.showNoCurriculums})}*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default EWSCheckBoxes;