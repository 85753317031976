import { defineMessages } from 'react-intl'
import React from 'react';

export default defineMessages({
  add_comment: `Add new comment`,
  teacher_status: 'Teacher status',
  visibility: 'Visibility',
  visible:'Visible',
  not_visible:'Not visible',
  dont_change_visibility:'Do not change visibility',
  extra_adjustments: 'Extra adjustments',
})
