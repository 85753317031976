import React, { useEffect, useState } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import request from '../../libs/request';
import { useWindowDimensions } from '../../libs/hooks';
import messages from './messages';

const Tou = (props) => {
  const { onDone, country } = props;
  const [acceptCB, setAcceptCB] = useState(false);
  const [tou, setTou] = useState();
  const dimensions = useWindowDimensions();
  const { formatMessage } = useIntl();

  const getTou = async () => {
    let lang;
    switch (country) {
      case 'SE':
        lang = 'sv';
        break;
      case 'NL':
        lang = 'nl';
        break;
      default:
        lang = 'en';
    }
    const url = `v3/schools/user-agreement/${lang}/`;
    const { data } = await request.get(url);
    setTou(data);
  };

  useEffect(() => {
    getTou();
  }, []);

  const accept = async () => {
    await request.post('/users/agreement-seen/');
    onDone();
  };

  return (
    <div>
      <div
        style={{
          width: `${dimensions.width - 100}px`,
          height: `${dimensions.height - 200}px`,
          padding: '20px',
          overflow: 'scroll',
          flexDirection: 'column',
        }}
      >
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: tou?.content }}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
      <Checkbox onChange={setAcceptCB} label={formatMessage({ ...messages.accept_tou_text })}/>
      <Button disabled={!acceptCB} onClick={accept}>
        {formatMessage({ ...messages.accept_tou_button })}
      </Button>
      </div>
    </div>
  );
};

export default Tou;
