import React, { useEffect, useRef, useState } from 'react';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import logo from '../../assets/studybee_logo.png';
import PageLoader from '../PageLoader';
import authLib from '../../libs/auth';
import SelectCountry from './selectCountry';
import CheckDomain from './checkDomain';
import SelectSchool from './selectSchool';
import Tou from './tou';
import { login, logout } from '../../redux/reducers/auth';

const GET_GOOGLE_USER = gql`
  query($accessToken: String!) {
    googleUserInfo(accessToken: $accessToken) {
      id
      name {
        givenName
        familyName
        fullName
      }
      emailAddress
    }
  }
`;

const USER_INFO = gql`
  {
    userInfo {
      id
      email
      first_name
      last_name
      is_teacher
      is_admin
      superAdminSchoolIds
      adminSchoolIds
    }
  }
`;

const setupSteps = ['country', 'domain', 'school', 'TOU', 'done'];

const Setup = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const [currentStep, setCurrentStep] = useState(setupSteps[0]);
  const selectedCountry = useRef();

  const [getGoogleUserInfo] = useLazyQuery(GET_GOOGLE_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { googleUserInfo: gUser } = data;
      setUser(gUser);
    },
    onError: (error) => {
      console.log('error getting google user: ', error.networkError);
      dispatch(logout());
    },
  });

  const [getUserInfo] = useLazyQuery(USER_INFO, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.userInfo) {
        const { userInfo } = data;
        const { adminSchoolIds: adminSchools, is_teacher: isTeacher } = userInfo;
        if (isTeacher) {
          const user = {
            ...userInfo,
            isTeacher,
            adminSchools,
          };

          authLib.storeUser({ user });
          login({ user });
          window.location.reload();
        }
      } else {
        dispatch(logout());
      }
    },
    onError: async () => {
      dispatch(logout());
    },
  });

  useEffect(() => {
    const accessToken = authLib.getAccessToken();
    getGoogleUserInfo({ variables: { accessToken } });
  }, []);

  if (!user) {
    return <PageLoader />;
  }

  const nextStep = () => {
    const number = setupSteps.findIndex((s) => s === currentStep);
    setCurrentStep(setupSteps[number + 1]);
  };
  const previousStep = () => {
    const number = setupSteps.findIndex((s) => s === currentStep);
    setCurrentStep(setupSteps[number - 1]);
  };
  return (
    <div>
      <img
        alt={'StudyBee'}
        src={logo}
        style={{
          width: '150px',
          position: 'absolute',
          right: '  20px',
          top: '20px',
        }}
      />
      {currentStep === 'country' && <SelectCountry onDone={(country) => {
        selectedCountry.current = country;
        nextStep();
      }} />}
      {currentStep === 'domain' && (
        <CheckDomain
          user={user}
          onDone={nextStep}
          onBack={previousStep}
        />
      )}
      {currentStep === 'school' && (
        <SelectSchool
          country={selectedCountry.current}
          onDone={nextStep}
          onBack={previousStep}
        />
      )}
      {currentStep === 'TOU'
      && <Tou country={selectedCountry.current} onDone={getUserInfo}/>}
    </div>
  );
};
export default Setup;
