import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {getUrlParams} from "../../utils";


const useParams = () => {
  const history = useHistory();
  const [params, setParams] = useState();

  useEffect(() => {
    setParams(getUrlParams(history.location.pathname));
  },[]);

  useEffect(() => {
    return history.listen((location) => {
      setParams(getUrlParams(location.pathname));
    })
  }, [history])

  return params;
};

export default useParams;