import React, {useState} from 'react';
import {
  Card,
  Header,
  Icon,
  Segment,
  Divider,
  Image,
  Label,
  Checkbox,
  Button,
  TextArea,
  Comment,
  Tab,
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import {useIntl} from 'react-intl';
import {useMutation} from '@apollo/client';
import {useSelector} from 'react-redux';
import globalMessages from '../../../../libs/i18n/messages';
import messages from './messages';
import Modal, {ModalHeader, ModalContent, ModalFooter} from '../../../Modal';
import EWSStatusRadioButton from '../../EWSStatusRadioButton';
import StudentProgress from '../../StudentProgress';
import * as EWSutils from '../../utils';
import './style.scss';
import {fonts} from '../../../../theme';
import colors from '../../../../theme/colors';
import {TOKEN_EXPIRE_BLOCK_UI_THRESHOLD} from '../../../App/tokenExpireChecker';
import {
  ADD_EWS_COMMENT,
  PUBLISH_EWS_STATUS,
  UPDATE_COURSE_ASSESSMENT,
  UPDATE_EWS_STATUS,
  UPDATE_EXTRA_ADJUSTMENTS
} from "../../../../client";
import {STATUS_BY_CODE} from "../../utils";

const {STATUS_BY_COLOR} = EWSutils;
const {GREEN, YELLOW, RED} = EWSutils;

export default function EWSPopupDetails(props) {
  const {
    status,
    student,
    course,
    studentCourse: {studentProgress = false},
    onSave,
    onCancel,
    extraAdjustments,
    editable,
  } = props;
  const {teachers} = course;
  const assessment = student?.courseAssessments?.find(a => a.course_definition_id === course.id);
  // console.log('xxx ', {assessment});
  const {extraAdjustmentsLog, statusLog, commentsLog} = student;
  const {formatMessage} = useIntl();
  const {logDays, logsByDay} = EWSutils.getLogs({
    earlyWarningSystemExtraAdjustmentLogs: extraAdjustmentsLog?.filter(e => e.course_definition_id === course.id),
    earlyWarningSystemCommentLogs: commentsLog?.filter(e => e.course_definition_id === course.id),
    earlyWarningSystemStatusLogs: statusLog?.filter(e => e.course_definition_id === course.id),
  });

  const isStatusByAdmin = true;
  const [publishStatus] = useMutation(PUBLISH_EWS_STATUS);
  const [saveCourseAssessment] = useMutation(UPDATE_COURSE_ASSESSMENT);
  const [updateEws] = useMutation(UPDATE_EWS_STATUS);
  const [updateExtraAdjustments] = useMutation(UPDATE_EXTRA_ADJUSTMENTS);
  const [addEwsComment] = useMutation(ADD_EWS_COMMENT);

  const initialSelectedExtraAdjustments = EWSutils.getCurrentExtraAdjustments(extraAdjustmentsLog?.filter(e => e.course_definition_id === course.id));
  const [selectedExtraAdjustments, setSelectedExtraAdjustments] = useState(initialSelectedExtraAdjustments);
  const [selectedStatus, setStatus] = useState();
  const [comment, setComment] = useState('');
  const [feedback, setFeedback] = useState(assessment?.feedback);
  const [feedbackPublished, setFeedbackPublished] = useState(assessment?.feedback_published);
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [saving, setSaving] = useState();
  let courseStatusLog = statusLog?.filter(e => e.course_definition_id === course.id);
  const initialStatusPublished = courseStatusLog.length?courseStatusLog[0].published:false;
  const [statusPublished, setStatusPublished] = useState(initialStatusPublished);
  const [activeIndex, setActiveIndex] = useState(studentProgress ? 1 : 0);

  const added = selectedExtraAdjustments.filter((ea) => !initialSelectedExtraAdjustments.includes(ea));
  const removed = initialSelectedExtraAdjustments.filter((ea) => !selectedExtraAdjustments.includes(ea));

  const hasChanges = ((added.length > 0 || removed.length > 0) || comment || selectedStatus || (assessment.feedback !== feedback));

  const actions = () => (
    <div>
      <Card style={{width: '100%'}}>
        <Card.Content>
          <Image floated='right' src={student.photo_url} avatar/>
          <Card.Header>{course.name}</Card.Header>
          <Card.Meta>{course.code || 'no code'}</Card.Meta>
          <Card.Description>
            {formatMessage({...messages.takeActionDescription},
              {
                studentName: `${student.first_name} ${student.last_name}`,
                courseName: course.name,
                courseCode: course.code,
                b: (str) => <b>{str}</b>,
                i: (str) => <i>{str}</i>,
              })} <br/> <br/>
            <p style={{...fonts.MEDIUM, fontWeight: '800'}}>{formatMessage({...messages.teachers})}</p>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {teachers?.map((teacher) =>
                <a
                  href={`mailto:${teacher?.email}`}
                  style={{
                    ...fonts.MEDIUM,
                    marginRight: '10px',
                  }}>{teacher?.first_name} {teacher?.last_name}
                </a>
              )}
            </div>
          </Card.Description>
        </Card.Content>
      </Card>
      {editable && (
        <>
          <Segment.Group horizontal>
            <Segment style={{width: '50%', display: 'flex', flexDirection: 'column'}}>
              <div style={{marginBottom: 15, flex: '1 1 100%', display: 'flex', flexDirection: 'column'}}>
                <Header as='h4'>
                  <Icon name='life ring'/>
                  <Header.Content>
                    {formatMessage({...messages.extraAdjustments})}
                  </Header.Content>
                </Header>
                <div style={{margin: '5px 0 0 4px', flex: '1 1 100%'}}>
                  {extraAdjustments
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((extraAdjustment) => (
                      <div key={`extra-adjustment-${extraAdjustment.id}`}>
                        <Checkbox
                          label={extraAdjustment.name}
                          checked={selectedExtraAdjustments.includes(extraAdjustment.id)}
                          onChange={(e, {checked}) => {
                            if (checked) {
                              setSelectedExtraAdjustments([
                                ...selectedExtraAdjustments,
                                extraAdjustment.id,
                              ]);
                            } else {
                              setSelectedExtraAdjustments(
                                selectedExtraAdjustments.filter(
                                  (eaId) => eaId !== extraAdjustment.id,
                                ),
                              );
                            }
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <Button
                loading={saving === 'adjustments'}
                primary
                disabled={!(added.length > 0 || removed.length > 0)}
                size='small'
                style={{width: '100%'}}
                onClick={async () => {
                  try {
                    setSaving('adjustments');
                    await updateExtraAdjustments({
                      variables: {
                        adjustments: selectedExtraAdjustments,
                        courseId: course.id,
                        studentId: student.id,
                      }
                    });
                    setSaving(null);
                    onSave();
                  } catch (e) {
                    setSelectedExtraAdjustments(initialSelectedExtraAdjustments);
                  }
                }}
              >
                {formatMessage({...messages.update})}
              </Button>
            </Segment>
            <Segment style={{width: '50%', display: 'flex', flexDirection: 'column'}}>
              <Header as='h4'>
                <Icon name='compass'/>
                <Header.Content>{formatMessage({...messages.status})}</Header.Content>
                <div style={{position: 'absolute', right: 18, top: 12, cursor: 'pointer'}}>
                  <span style={{marginRight: '6px'}}>
                    {statusPublished ? formatMessage({...messages.published}) : formatMessage({...messages.not_published})}
                  </span>
                  <Icon
                    name={statusPublished ? 'eye' : 'eye slash'}
                    onClick={async () => {
                      setStatusPublished(!statusPublished);
                      try {
                        await publishStatus({
                          variables: {
                            studentId: student.id,
                            courseId: course.id,
                            published: !statusPublished,
                          },
                        });
                        onSave();
                      } catch (e) {
                        console.log('Error could not change publish status');
                        onSave();
                      }
                    }}
                  />
                </div>
              </Header>
              <div style={{display: 'flex', height: '20px', alignItems: 'center'}}>
                <span style={{...fonts.MEDIUM, marginRight: '10px', lineHeight: '14px'}}>
                  {formatMessage({...messages.current_status})}
                </span>
                <Button color={STATUS_BY_CODE[status.adminStatus]?.toLowerCase()}/>
              </div>
              <div
                style={{
                  marginTop: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 1 100%',
                }}
              >
                <div style={{flex: '1 1 100%', marginBottom: 10}}>
                  {[RED, YELLOW, GREEN].map((color) => (
                    <EWSStatusRadioButton
                      key={`radio-${color}`}
                      color={color}
                      isStatusByAdmin={isStatusByAdmin}
                      checked={color === selectedStatus}
                      onChange={(status) => {
                        setStatus(status);
                      }}
                    />
                  ))}
                </div>
                <Button
                  loading={saving === 'status'}
                  primary
                  size='small'
                  disabled={!selectedStatus}
                  style={{width: '100%'}}
                  onClick={async () => {
                    try {
                      setSaving('status');
                      await updateEws({
                        variables: {
                          status: {
                            student_id: student.id,
                            course_id: course.id,
                            status: STATUS_BY_COLOR[selectedStatus],
                            published: statusPublished||false,
                          },
                        },
                      })
                      setSaving(null);
                      setStatus(null);
                      onSave({
                        type: 'CHANGE_STATUS',
                        status: STATUS_BY_COLOR[selectedStatus],
                      });
                    } catch (e) {
                      setSelectedExtraAdjustments(initialSelectedExtraAdjustments);
                    }
                  }}
                >
                  {formatMessage({...messages.approve})}
                </Button>
              </div>
            </Segment>
          </Segment.Group>
          <Segment style={{display: 'flex', flexDirection: 'column'}}>
            <div
              style={{
                marginBottom: 15,
                flex: '1 1 100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Header as='h4'>
                <Icon name='student'/>
                <Header.Content>{formatMessage({...messages.studentFeedback})}</Header.Content>

                <div style={{
                  position: 'absolute', right: 18, top: 12, cursor: 'pointer',
                }}>
                  <span style={{marginRight: '6px'}}>
                    {feedbackPublished
                      ? formatMessage({...messages.published})
                      : formatMessage({...messages.not_published})}
                  </span>
                  <Icon
                    onClick={async () => {
                      setFeedbackPublished(!feedbackPublished);
                      assessment.feedback_published = !feedbackPublished;
                      try {
                        await saveCourseAssessment({
                          variables: {
                            assessments: [
                              {
                                id: assessment.id,
                                feedback_published: !feedbackPublished,
                              },
                            ],
                          },
                        });
                        onSave();
                      } catch (e) {
                        console.log('Error e: ', e);
                        onSave();
                      }
                    }}
                    name={feedbackPublished ? 'eye' : 'eye slash'}
                  />
                </div>
              </Header>
              <div className='ui form' style={{margin: '5px 0 0 4px', flex: '1 1 100%'}}>
                <TextArea
                  value={feedback}
                  onChange={(e) => {
                    setFeedback(e.target.value);
                  }}
                />
              </div>
            </div>

            <Button
              loading={saving === 'feedback'}
              primary
              disabled={assessment.feedback === feedback}
              size='small'
              style={{width: '100%'}}
              onClick={async () => {
                setSaving('feedback');
                assessment.feedback = feedback;
                await saveCourseAssessment({
                  variables: {
                    assessments: [
                      {
                        id: assessment.id,
                        feedback,
                      },
                    ],
                  },
                });
                setSaving(null);
                onSave();
              }}
            >
              {formatMessage({...messages.updateFeedback})}
            </Button>
          </Segment>
          <Segment style={{display: 'flex', flexDirection: 'column'}}>
            <div
              style={{
                marginBottom: 15,
                flex: '1 1 100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Header as='h4'>
                <Icon name='life ring'/>
                <Header.Content>{formatMessage({...messages.comment})}</Header.Content>
              </Header>
              <div className='ui form' style={{margin: '5px 0 0 4px', flex: '1 1 100%'}}>
                <TextArea
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </div>
            </div>
            <Button
              loading={saving === 'comment'}
              primary
              disabled={!comment}
              size='small'
              style={{width: '100%'}}
              onClick={async () => {
                try {
                  setSaving('comment');
                  await addEwsComment({
                    variables: {
                      comment,
                      courseId: course.id,
                      studentId: student.id
                    }
                  });
                  setSaving(null);
                  onSave({
                    type: 'ADD_COMMENT',
                    comment,
                  });
                  setComment('');
                } catch (e) {
                  setComment('');
                }
              }}
            >
              {formatMessage({...messages.addCommment})}
            </Button>
          </Segment>

          <Divider hidden/>
        </>
      )}
      <Header as='h4'>
        <Icon name='history'/>
        <Header.Content>{formatMessage({...messages.logs})}</Header.Content>
      </Header>
      <Segment>
        {!logDays.length ? <span>{formatMessage({...messages.noLogs})}</span> : ''}
        {logDays.map((day, i) => {
          const {
            status, admins, logs: logExtraAdjustments, comments,
          } = logsByDay[day];
          const even = i % 2;

          const getExtraAdjustmentName = (ea) => ea.name;
          const addedLogs = logExtraAdjustments
            .filter((log) => log.status > 0 && log.isExtraAdjustmentLogs)
            .map(getExtraAdjustmentName);
          const removedLogs = logExtraAdjustments
            .filter((log) => log.status < 0 && log.isExtraAdjustmentLogs)
            .map(getExtraAdjustmentName);
          const statusLogs = logExtraAdjustments.filter((log) => log.isStatusLog);
          if (!addedLogs.length && !removedLogs.length && !statusLogs.length && !comments.length) {
            return null;
          }
          const texts = (() => {
            const textArray = [];
            if (addedLogs.length > 0) {
              textArray.push(
                <span key={`${day}-0`}>
                  {formatMessage(
                    {...messages.added},
                    {
                      addedLogs: addedLogs.join(', '),
                      b: (str) => <b>{str}</b>,
                    },
                  )}
                </span>,
              );
            }

            if (removedLogs.length > 0) {
              textArray.push(
                <span key={`${day}-1`}>
                  {formatMessage(
                    {...messages.removed},
                    {
                      removedLogs: removedLogs.join(', '),
                      b: (str) => <b>{str}</b>,
                    },
                  )}
                </span>,
              );
            }

            if (statusLogs.length > 0) {
              textArray.push(
                <span key={`${day}-2`}>
                  {formatMessage({...messages.changedStatusTo}, {b: (str) => <b>{str}</b>})}
                  <Label color={_.lowerCase(statusLogs[0].status)}/>
                </span>,
              );
            }

            if (textArray.length === 3) {
              textArray.splice(1, 0, ', ');
            }

            if (textArray.length > 1) {
              textArray.splice(textArray.length - 1, 0, ' and ');
            }

            return textArray;
          })();
          const color = status ? _.lowerCase(status) : undefined;
          return (
            <div
              key={`${day}-logExtraAdjustments`}
              style={{
                marginBottom: 5,
                display: 'flex',
                alignItems: 'center',
                padding: 10,
                background: even ? 'rgba(0,0,0,.03)' : 'none',
              }}
            >
              <Label color={color}>{moment(day).format('YYYY/MM/DD')}</Label>
              <div
                style={{
                  marginLeft: 10,
                  border: '1px solid #ddd',
                  borderWidth: '0 0 0 1px',
                  paddingLeft: 10,
                }}
              >
                {(addedLogs.length > 0 || removedLogs.length > 0 || statusLogs.length > 0) && (
                  <div>
                    {texts}
                    <div>
                      {formatMessage({...messages.by})}{' '}
                      <i>{admins.map((admin) => admin.fullName || admin.email || 'Unknown').join(', ')}</i>
                    </div>
                  </div>
                )}
                {comments.length ? (
                  <Comment.Group>
                    <Header as='h4' dividing>
                      {formatMessage({
                        ...messages.commentsHeader,
                      })}
                    </Header>
                    {comments.map((comment) => (
                      <Comment key={`comment-${comment.id}`}>
                        <Comment.Avatar src={comment.admin.photo_url}/>
                        <Comment.Content>
                          <Comment.Author
                            as='span'>{comment.admin.fullname?.length > 1 ? comment.admin.fullname : comment.admin.email}</Comment.Author>
                          <Comment.Metadata>
                            <div>{moment(comment.created).format('HH:mm')}</div>
                          </Comment.Metadata>
                          <Comment.Text>{comment.comment}</Comment.Text>
                        </Comment.Content>
                      </Comment>
                    ))}
                  </Comment.Group>
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        })}
      </Segment>
    </div>
  );

  const panes = [
    {
      menuItem: formatMessage({...messages.actions}),
      render: () => <Tab.Pane>{actions()}</Tab.Pane>,
    },
    {
      menuItem: formatMessage({...messages.studentProgress}),
      render: () => (
        <Tab.Pane>
          <div style={{minHeight: '300px'}}>
            <StudentProgress studentId={student.id} courseId={course.id} studentName={student.fullname} courseName={course.name}/>
          </div>
        </Tab.Pane>
      ),
    },
  ];
  const timeLeftToExpire = useSelector((state) => state.auth.timeLeftToExpire);
  return (
    <Modal isOpen size='large'>
      <div id={timeLeftToExpire > 0 && timeLeftToExpire < TOKEN_EXPIRE_BLOCK_UI_THRESHOLD ? 'blur' : ''}>
        <ModalHeader>
          <p>
            {`${student.first_name} ${student.last_name}`} - {course.name}{' '}
            <span style={{fontSize: 12, display: 'inline-block'}}>({student.email})</span>
          </p>
        </ModalHeader>
        <ModalContent>
          <Tab
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={(e, {index}) => setActiveIndex(index)}
          />
        </ModalContent>
        <ModalFooter>

          <Button
            color='grey'
            onClick={() => {
              if (hasChanges) {
                setShowCloseDialog(true);
              } else {
                onCancel();
              }
            }}
          >
            {formatMessage({...globalMessages.close})}
          </Button>

          {showCloseDialog
          && <div style={{
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: colors.dimmed,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}>
            <div style={{
              backgroundColor: colors.white,
              borderRadius: '10px',
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}>
              <span style={{...fonts.MEDIUM}}>{formatMessage({...messages.closeWithoutSave, marginTop: '10px'})}</span>
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
                <Button color='grey' content={formatMessage({...globalMessages.cancel})}
                        onClick={() => setShowCloseDialog(false)}/>
                <Button color='grey' content={formatMessage({...globalMessages.close})} onClick={onCancel}/>
              </div>
            </div>
          </div>
          }

        </ModalFooter>
      </div>
    </Modal>
  );
}
