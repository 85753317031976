import { Button, Dropdown } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { fonts } from '../../theme';
import request from '../../libs/request';
import messages from './messages';


const SelectSchool = (props) => {
  const { onBack, onDone, country } = props;
  const [school, setSchool] = useState();
  const { formatMessage } = useIntl();
  const [options, setOptions] = useState([]);
  const [error, setError] = useState();

  const getSchools = async () => {
    const schools = (await request.get(`schools/school/?country_code=${country}`)).data;
    setOptions(schools);
  };

  useEffect(() => {
    getSchools();
  }, []);

  const saveSchool = async () => {
    try {
      await request.post('/schools/user_setup/', { school });
      onDone();
    } catch (e) {
      console.log('saveSchool error: ', e.message);
      setError(formatMessage({ ...messages.verified_teacher_error }));
    }
  };

  return (
    <div style={{ width: '400px' }}>
      <p style={{ ...fonts.LARGE }}>{formatMessage({ ...messages.select_school_title })}</p>
      <Dropdown
        fluid
        search
        selection
        options={options.map((s) => ({ key: s.id, text: s.title, value: s.id }))}
        placeholder={formatMessage({ ...messages.select_school_hint })}
        onChange={(e, data) => setSchool(data.value)}
      />
      {error
      && <span style={{ ...fonts.MEDIUM, fontWeight: '800', marginTop: '20px' }}>{error}</span>}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
        <Button color={'yellow'} onClick={onBack}>
          {formatMessage({ ...messages.back })}
        </Button>
        {!error
        && <Button disabled={!school} color={'yellow'} onClick={saveSchool}>
          {formatMessage({ ...messages.continue })}
        </Button>
        }
      </div>
    </div>
  );
};
export default SelectSchool;
