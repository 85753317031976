import { defineMessages } from 'react-intl';

export default defineMessages({
  symbol_explanation: 'Symbol explanation',
  in_collapsed_mode: 'In collapsed mode',
  in_expanded_mode: 'In expanded mode',
  legend_text_striped_status: 'A striped status indicates that the status is based on the input from the system.',
  legend_text_solid_status: 'A solid status indicates that the status is based on the teachers input.',
  legend_text_extra_adjustments_small: 'Indication for extra adjustments, the number shows how many.',
  legend_text_feedback: 'Indication for overall feedback.',
  legend_text_grade_small: 'If a grade is available it will be displayed in the bottom right corner.',
  legend_text_empty: 'An empty status means that the is no information available.',
  legend_text_dark_status: 'A dark gray status means that the student does not take this course.',
  legend_text_solid_smiley: 'A solid indicator indicates that the status is from a teacher.',
  legend_text_striped_smiley: 'A striped indicator indicates that the status is based on input from the system.',
  legend_text_grade: 'Show that there is a grade available.',
  legend_text_extra_adjustments: 'Shows that theree are extra adjustments, the number shows how many',
  view_intro: 'Show introduction',
  instructions: 'Instructions',
  eye_hide: 'The eye is used to hide courses in the current filter.',
  eye_unhide: 'This eye is used to un-hide courses in the current filter.',
});
