import React, {useEffect, useRef, useState} from 'react';
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {ScrollSync, ScrollSyncPane} from "react-scroll-sync";
import {animated, useSpring} from "react-spring";
import {colors, fonts} from "../../theme";
import {useScrollPosition, useWindowDimensions} from "../../libs/hooks";
import StudentTableHeader from "./StudentTable/studentTableHeader";
import {
  ADD_EWS_COMMENT,
  PUBLISH_EWS_STATUS,
  SET_VIEW_FILTER,
  UPDATE_EWS_STATUS,
  UPDATE_EXTRA_ADJUSTMENTS
} from "../../client";
import messages from "./StudentTable/messages";
import ewsmessages from "../EWS/EWSComponent/messages"
import {useIntl} from "react-intl";
import StudentItem from "./StudentTable/studentItem";
import EWSFilter from "./EWSFilter";
import {Divider, Dropdown, Loader} from "semantic-ui-react";
import StudentCourse from "./StudentTable/studentCourse";
import _ from "lodash";
import EWSPopupDetails from "./EWSPopupDetails/EWSPopupDetailsComponent";
import EWSCheckBoxes from "./EWSCheckBoxes";
import SelectOptions from "./StudentTable/selectOptions";
import {getCurrentExtraAdjustments, newestStatus, STATUS_TO_SCORE} from "./utils";
import Promise from "bluebird";
import noSchoolImg from '../../assets/no_school.png';
import noFilterImg from '../../assets/no_filter.png';
import informationImg from '../../assets/information.png';
import store from "../../libs/store";
import Legend from "./legend";
import Splash from "./Splash";
import {useDispatch} from "react-redux";
import {setError, setUpdating} from "../../redux/reducers/app";

const style = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    position: 'relative',
    marginTop: '20px',
    paddingLeft: '10px',
    paddingBottom: '10px',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
  },

  studentContainer: {
    boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    overflow: 'hidden',
    border: '0px solid red',
  },
  studentsScroll: {
    border: '0px solid blue',
    overflow: 'scroll',
    width: '260px',
    margin: '0px -10px -10px 0px',
    // padding: '0px 0px 10px 0px',
  },
  coursesScroll: {
    display: 'flex',
    flexDirection: 'row',
    border: '0px solid green',
    overflow: 'scroll',
    flex: 1,
    margin: '0px -10px -10px 0px',
  },
};

const EWS_BY_CLASSROOM_QUERY = gql`
  query($classroomIds: [String]!) {
    ewsDataByClassroom(classroomIds: $classroomIds) {
      extraAdjustments
      courses
      filters
      students {
        id
        first_name
        last_name
        photo_url
        email
        courseStatus
        courseAssessments
        extraAdjustmentsLog
        statusLog
        commentsLog
      }
    }
  }
`;

const EWS_BY_STUDENT_QUERY = gql`
  query($schoolId: Int!, $studentIds: [Int], $studentListIds: [Int], $getMentorStudents: Boolean) {
    ewsDataByStudents(schoolId: $schoolId, studentIds: $studentIds, studentListIds: $studentListIds, getMentorStudents: $getMentorStudents) {
      extraAdjustments
      courses
      filters
      students {
        id
        first_name
        last_name
        photo_url
        email
        courseStatus
        courseAssessments
        extraAdjustmentsLog
        statusLog
        commentsLog
      }
    }
  } 
`;

const SCHOOLS_QUERY = gql`
{
  schools {
    id
    name
    isSuperAdmin
    isAdmin
    isTeacher
  }
}
`;

const CLASS_VIEW = 'classes';
const STUDENT_VIEW = 'students';

const EWS = ({match, history, showError}) => {
  const {params} = match;
  const seenLegend = store.get('seenLegend');
  const seenSplash = store.get('seenSplash');
  const dispatch = useDispatch();
  const [showLegend, setShowLegend] = useState(!seenLegend);
  const [showSplash, setShowSplash] = useState(!seenSplash);
  const {schoolId, studentIds, ids: classroomIds} = params
  const {formatMessage} = useIntl();
  const animatingRef = useRef({animating: false});
  const dimensions = useWindowDimensions();
  const [highlight, setHighlight] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [activeFilter, setActiveFilter] = useState({});
  const [studentCourse, setStudentCourse] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [showHidden, setShowHidden] = useState(false);

  const [publishStatus] = useMutation(PUBLISH_EWS_STATUS);
  const [addEwsComment] = useMutation(ADD_EWS_COMMENT);
  const [updateEws] = useMutation(UPDATE_EWS_STATUS);
  const [updateExtraAdjustments] = useMutation(UPDATE_EXTRA_ADJUSTMENTS);
  const {loading: loadingSchools, error: errorSchools, data: schoolsData} = useQuery(SCHOOLS_QUERY,
    {fetchPolicy: 'cache-and-network'}
  );

  const activeView = history.location.pathname.includes(CLASS_VIEW) ? CLASS_VIEW : STUDENT_VIEW

  const studentIdsForApiCall = studentIds?.split(',').filter(i => !i.includes('list') && !i.includes('mentor')).map(i => parseInt(i));
  const studentListIdsForApiCall = studentIds?.split(',').filter(i => i.includes('list')).map(i => parseInt(i.replace('list_', '')));
  const getMentorStudents =  studentIds?.split(',').find(i => i === 'mentor')?.length > 0;
  const classroomIdsForApiCall = classroomIds?.split(',');

  const [fetchClassrooms, {
    loading: loadingClassrooms,
    error: errorClassrooms,
    data: classroomsData,
    refetch: refetchClassrooms
  }] = useLazyQuery(EWS_BY_CLASSROOM_QUERY, {fetchPolicy: 'cache-and-network'});


  const [fetchStudents, {
    loading: loadingStudents,
    error: errorStudents,
    data: studentsData,
    refetch: refetchStudents
  }] = useLazyQuery(EWS_BY_STUDENT_QUERY, {fetchPolicy: 'cache-and-network'});

  useEffect(() => {
    if (schoolId && studentListIdsForApiCall) {
      fetchStudents({
        variables: {
          schoolId: parseInt(schoolId),
          studentIds: studentIdsForApiCall,
          studentListIds: studentListIdsForApiCall,
          getMentorStudents: getMentorStudents,
        }
      })
    }
    if (schoolId && classroomIdsForApiCall) {
      fetchClassrooms({
        variables: {
          classroomIds: classroomIdsForApiCall,
        }
      });

    }
  }, [params]);

  const loading = loadingStudents || loadingClassrooms;
  const dataToShow = activeView === STUDENT_VIEW ? studentsData?.ewsDataByStudents : classroomsData?.ewsDataByClassroom;
  const [setViewFilter] = useMutation(SET_VIEW_FILTER, {
    onError: async (e) => {
      console.log('Error setting filter', e);
      refetch();
      showError(
        formatMessage({...messages.error}),
        formatMessage({...messages.error_save_filter}),
      );
    },
  });

  useEffect(() => {
    setSelected([]);
  }, [params, selectMode]);

  useEffect(() => {
    // Fix for legacy filters that used a different path
    let needsSave = false;
    const filterObject = dataToShow?.filters?.reduce((acc, f) => {
      let view = f.view;
      if (view.includes('klass_')) {
        removeLegacyViewFilter(view);
        needsSave = true;
        view = view.replace('classes', 'students')?.replace('klass_', 'list_');
      }
      if (!(view in acc)) {
        acc[view] = f?.hiddenCourses;
      } else {
        acc[view] = [...new Set([acc[view], f?.hiddenCourses].join(',').split(','))].join(',');
      }
      return acc;
    }, {});

    const activeFilter = {view, hiddenCourses: filterObject ? filterObject[view] : ''};
    // Remove hiddenCourse filter for courses that are not longer in view
    activeFilter.hiddenCourses = activeFilter?.hiddenCourses?.split(',')?.filter(sid => courses.find(c => c.id === parseInt(sid))).join(',');
    setActiveFilter(activeFilter);
    if (needsSave) {
      saveActiveViewFilter(activeFilter);
    }
  }, [params, loadingStudents]);

  const refetch = async () => {
    if (activeView === CLASS_VIEW) {
      await refetchClassrooms();
    } else if (activeView === STUDENT_VIEW) {
      await refetchStudents();
    }
  };

  useEffect(() => {
    if (!loading) {
      dispatch(setUpdating(false));
    }
  }, [loading]);

  const saveActiveViewFilter = async (filter) => {
    await setViewFilter({variables: {view: filter.view, hiddenCourses: filter.hiddenCourses}});
  };

  const removeLegacyViewFilter = async (view) => {
    await setViewFilter({variables: {view, hiddenCourses: ''}});
  };

  const schoolsOptions = schoolsData?.schools?.map(s => ({
    key: s.id,
    value: s.id,
    text: s.name,
  }));

  if(schoolsOptions?.length === 1 && !schoolId){
    history.push(`/ews/${schoolsOptions[0].value}`);
  }
  const view = window.location.href.split('/').splice(5).join('/');
  let timeout;
  let scrolling = false;
  let lastHighlight;

  const [headerAnim, setHeaderAnim] = useSpring(() => ({
    top: -250,
    onStart: () => (animatingRef.current.animating = true),
    onRest: () => (animatingRef.current.animating = false),
  }));

  useScrollPosition(({prevPos, currPos}) => {
    const up = currPos.y > prevPos.y;
    if (up) {
      setHeaderAnim({top: 0});
    } else if (headerAnim.top !== -250) {
      setHeaderAnim({top: -250});
    }
  });

  const handleScroll = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      scrolling = false;
      setHighlight(lastHighlight);
    }, 500);
    if (!scrolling) {
      scrolling = true;
    }
  };

  const studentSelected = (checked, studentId) => {
    if (!checked) {
      setSelected(selected.filter((s) => !(s.studentId === studentId)));
    } else {
      setSelected([
        ...selected,
        ...students.find(s => s.id === studentId)?.courseStatus.map(cs => ({studentId, courseId: cs.courseId}))
      ]);
    }
  };

  const courseSelected = ({checked, courseId}) => {
    if (!checked) {
      setSelected(selected.filter((s) => !(s.courseId === courseId)));
    } else {
      const studentIds = students.filter(s => s.courseStatus.find(cs => cs.courseId === courseId)).map(s => s.id);
      setSelected([...selected, ...studentIds.map(id => ({courseId, studentId: id})), {studentId: 'all', courseId}]);
    }
  };

  const toggleHide = async (course) => {
    let hiddenCourses;
    const split = activeFilter.hiddenCourses?.split(',') || [];
    if (split.find((h) => parseInt(h) === course.id)) {
      hiddenCourses = split.filter((a) => parseInt(a) !== course.id).join(',');
    } else {
      hiddenCourses = activeFilter?.hiddenCourses
        ? `${activeFilter.hiddenCourses},${course.id}`
        : `${course.id}`;
    }
    // Updating ui here will cause flickering, overkill, just let it refresh
    // setActiveFilter({hiddenCourses, view});
    dispatch(setUpdating(true));
    await setViewFilter({variables: {view, hiddenCourses}});
    await refetch();
  };

  const onAcceptStatusMany = async () => {
    dispatch(setUpdating(true));
    try {
      await Promise.map(
        selected,
        (sel) => {
          if (sel.courseId === 'all' || sel.studentId === 'all') {
            return Promise.resolve();
          }
          const student = students.find((s) => s.id === sel.studentId);
          const status = student?.courseStatus?.find(l => l.courseId === sel.courseId);
          const adminStatusScore = status.adminStatus ? STATUS_TO_SCORE[status.adminStatus] : 0;
          const systemStatusScore = STATUS_TO_SCORE[status.systemStatus];
          if (!status?.systemStatus) {
            return Promise.resolve();
          }
          if (newestStatus(status) === 'admin') {
            return Promise.resolve();
          }
          if (adminStatusScore >= systemStatusScore) {
            return Promise.resolve();
          }

          return updateEws({
            variables: {
              status: {
                student_id: sel.studentId,
                course_id: sel.courseId,
                status: status.systemStatus,
                published: status?.ewsStatusPublished,
              },
            },
          })
        },
        {concurrency: 50},
      );
    } catch (e) {
      console.log('Error saving many: ', e);
      dispatch(setError({
        title: formatMessage({...messages.error}),
        message: formatMessage({...messages.error_save_status})
      }));
    }
    setSelectMode(false);
    refetch();
  };

  const changeExtraAdjustmentsMany = async (data) => {
    const {changed, comment} = data;
    dispatch(setUpdating(true));
    try {
      const promises = selected.filter(s => s.studentId !== 'all' && s.courseId !== 'all').map(selected => {
        const promises = [];
        let student = students?.find(s => s.id === selected.studentId);
        if (comment) {
          promises.push(addEwsComment({
            variables: {
              comment,
              courseId: selected.courseId,
              studentId: selected.studentId
            }
          }));
        }

        let currentAdjustments = getCurrentExtraAdjustments(student.extraAdjustmentsLog.filter(e => e.course_definition_id === selected.courseId));
        Object.keys(changed).forEach(seId => {
          const eId = parseInt(seId);
          if (changed[eId] && !currentAdjustments.find(c => c === eId)) {
            currentAdjustments.push(eId)
          } else if (!changed[eId]) {
            currentAdjustments = currentAdjustments.filter(c => c !== eId);
          }
        });
        const newVar = {
          variables: {
            adjustments: currentAdjustments,
            courseId: selected.courseId,
            studentId: selected.studentId,
          }
        };
        promises.push(updateExtraAdjustments(newVar));
        return promises;
      }).flat();
      await Promise.all(promises);
    } catch (e) {
      console.log('Error saving many: ', e);
      dispatch(setError({
        title: formatMessage({...messages.error}),
        message: formatMessage({...messages.error_save_adjustments})
      }));
    }
    setSelectMode(false);
    await refetch();
  };

  const changeStatusMany = async (data) => {
    dispatch(setUpdating(true));
    try {
      await Promise.map(
        selected,
        async (sel) => {
          if (sel.courseId === 'all' || sel.studentId === 'all') {
            return Promise.resolve();
          }
          if (data.comment) {
            await addEwsComment({variables: {comment: data.comment, courseId: sel.courseId, studentId: sel.studentId}});
          }
          if (data.status) {
            const courseStatus = students.find(s => s.id === sel.studentId)?.courseStatus?.find(cs => cs.courseId === sel.courseId);
            let published = courseStatus?.ewsStatusPublished || false;
            if (data.publish === 'publish') {
              published = true;
            }
            await updateEws({
              variables: {
                status: {
                  student_id: sel.studentId,
                  course_id: sel.courseId,
                  status: data.status,
                  published: published,
                },
              },
            })
          }
          if (data.publish && data.publish !== '') {
            await publishStatus({
              variables: {
                studentId: sel.studentId,
                courseId: sel.courseId,
                published: data.publish === 'publish',
              },
            });
          }
        },
        {concurrency: 50},
      );
    } catch (e) {
      console.log('Error saving many: ', e);
      dispatch(setError({
        title: formatMessage({...messages.error}),
        message: formatMessage({...messages.error_save_status})
      }));
    }
    setSelectMode(false);
    refetch();
  };


  const updateHighlight = (highlight) => {
    lastHighlight = highlight;
    if (!scrolling && !animatingRef.current.animating) {
      setHighlight(highlight);
    }
  };
  const updateHighlightDebounced = _.debounce(updateHighlight, 1, {trailing: true});

  const students = dataToShow?.students || [];
  let courses = dataToShow?.courses?.sort((a, b) => a.name.localeCompare(b.name)) || [];
  const archivedCourses = courses.filter(c => c.archived);
  courses = showArchived ? courses : courses.filter(c => !c.archived);
  const extraAdjustments = dataToShow?.extraAdjustments || [];

  const selectedStudent = students?.find(s => s.id === studentCourse?.studentId);
  const selectedCourse = courses?.find(c => c.id === studentCourse?.courseId);

  const filterSelected = !!(students.length || courses.length);

  const hiddenCourses = activeFilter?.hiddenCourses?.split(',')?.map(sid => parseInt(sid));
  let filteredCourses = courses;
  if (!showHidden) {
    filteredCourses = courses.filter(c => !hiddenCourses?.find(h => parseInt(h) === c.id));
  }

  const allCourses = [...new Set(students.map(s => s.courseStatus).flat().map(c => c.courseId))];
  filteredCourses = filteredCourses.filter(c => allCourses.find(id => id === c.id));

  return (
    <div>
      {studentCourse &&
      <EWSPopupDetails
        status={selectedStudent?.courseStatus?.find(cs => cs.courseId === studentCourse.courseId)}
        student={selectedStudent}
        course={selectedCourse}
        studentCourse={studentCourse}
        onSave={() => {
          refetch();
        }}
        onCancel={() => setStudentCourse(null)}
        courseDefinitionsAssessment={{}}
        extraAdjustments={extraAdjustments}
        editable={true}
        entities={{}}
      />
      }
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Dropdown
          style={{
            zIndex: 2000,
            padding: '0px 12px 0px 12px',
            border: `1px solid ${colors.borderColorDark}`,
            margin: '6px 12px 6px 6px',
            borderRadius: '6px',
            height: '38px',
            display: 'flex',
            alignItems: 'center',
          }}
          placeholder={formatMessage({...messages.select_school})}
          options={schoolsOptions}
          onChange={(e, data) => {
            history.push(`/ews/${data.value}`);
          }}
          value={schoolId || 0}
          trigger={
            <span style={{...fonts.MEDIUM}}>
              {schoolId ? schoolsOptions?.find(o => o.key === schoolId)?.text : formatMessage({...messages.select_school})}
            </span>
          }
        />
        <EWSFilter/>
        <img src={informationImg} style={{width: '30px', position: 'absolute', right: '18px'}}
             onClick={() => setShowLegend(true)}/>
      </div>
      <Divider/>
      {schoolId && filterSelected &&
      <EWSCheckBoxes selectMode={selectMode} setSelectMode={setSelectMode}
                     numberOfArchivedCourses={archivedCourses.length} onShowArchived={setShowArchived}
                     numberOfHiddenCourses={hiddenCourses?.filter(h => h).length || 0}
                     showHidden={showHidden}
                     onShowHidden={setShowHidden}
      />}
      {!dataToShow && !loading && !schoolId &&
      <div style={{display: 'flex'}}>
        <img src={noSchoolImg} style={{width: '400px', marginLeft: '100px'}}/>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          marginBottom: '55px',
          marginLeft: '25px',
          width: '400px'
        }}>
          <p style={{
            ...fonts.LARGE,
            fontSize: 30,
            marginBottom: '6px',
            color: colors.textColorBrighter
          }}>{formatMessage({...ewsmessages.no_school_selected})}</p>
          <p style={{
            ...fonts.LARGE,
            fontSize: '23px',
            color: colors.textColorBrighter
          }}>{formatMessage({...ewsmessages.school_filter_explain})}</p>
        </div>
      </div>
      }
      {!dataToShow && !loading && schoolId &&
      <div style={{display: 'flex'}}>
        <img src={noFilterImg} style={{width: '400px', marginLeft: '140px'}}/>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          marginBottom: '55px',
          marginLeft: '25px',
          width: '400px'
        }}>
          <p style={{
            ...fonts.LARGE,
            fontSize: 30,
            marginBottom: '6px',
            color: colors.textColorBrighter
          }}>{formatMessage({...ewsmessages.no_filter_selected})}</p>
          <p style={{
            ...fonts.LARGE,
            fontSize: '23px',
            color: colors.textColorBrighter
          }}>{formatMessage({...ewsmessages.school_filter_explain})}</p>
        </div>
      </div>
      }
      {!dataToShow && loading &&
      <div>
        <Loader active={true}>{formatMessage({...messages.loading})}</Loader>
      </div>
      }
      {!loading && dataToShow && (dataToShow.students.length === 0 || dataToShow.courses.length === 0) &&
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '140px',
        left: 0,
        right: 0,
        bottom: 0
      }}>
        <p style={{
          ...fonts.LARGE,
          fontSize: 30,
          marginBottom: '6px',
          color: colors.textColorBrighter
        }}>{formatMessage({...messages.noStudents})}</p>
      </div>
      }
      {dataToShow && schoolId && (studentListIdsForApiCall || classroomIdsForApiCall) && dataToShow?.students?.length > 0 && dataToShow?.courses?.length > 0 &&
      <div style={style.container}>
        <ScrollSync>
          <div style={style.innerContainer}>
            <animated.div
              style={{
                position: 'sticky',
                zIndex: 1000,
                ...headerAnim,
                borderBottom: `1px solid ${colors.black}`,
                backgroundColor: colors.white,
              }}
            >
              {selectMode && (
                <SelectOptions
                  adjustments={extraAdjustments}
                  enabled={selected.length > 0}
                  onStatusClick={changeStatusMany}
                  selectedStudentsAndCourses={selected}
                  onExtraAdjustmentsSave={changeExtraAdjustmentsMany}
                  onAcceptStatus={onAcceptStatusMany}
                  students={students}
                />
              )}
              <div style={{overflow: 'hidden'}}>
                <ScrollSyncPane>
                  <StudentTableHeader
                    courses={filteredCourses}
                    expanded={expanded}
                    selected={selected}
                    students={students}
                    highlight={highlight}
                    setSelected={courseSelected}
                    setExpanded={setExpanded}
                    expandCollapseAll={(expand) => expand ? setExpanded(filteredCourses.map(c => c.id)) : setExpanded([])}
                    toggleHide={toggleHide}
                    selectMode={selectMode}
                    hiddenCourses={hiddenCourses}
                    schoolId={schoolId}
                  />
                </ScrollSyncPane>
              </div>
            </animated.div>
            <div style={{...style.studentContainer}}>
              <ScrollSyncPane>
                <div onScroll={handleScroll} style={style.studentsScroll}>
                  {students.map(s => (
                    <div key={s.id}>
                      <StudentItem
                        student={s}
                        highlight={highlight?.studentId === s.id}
                        setSelected={(e, {checked}) => studentSelected(checked, s.id)}
                        schoolId={schoolId}
                        bgColor={colors.borderColor}
                        selectMode={selectMode}
                      />
                    </div>
                  ))}
                </div>
              </ScrollSyncPane>

              <div style={{display: 'flex', flex: 1, overflow: 'hidden'}}>
                <ScrollSyncPane>
                  <div
                    onScroll={handleScroll}
                    style={{...style.coursesScroll, width: `${dimensions.width - 400}px`}}
                    onMouseLeave={updateHighlightDebounced}
                  >
                    {/*alla statusar*/}
                    {filteredCourses.map(course => {
                      const isExpanded = expanded.find((e) => e === course.id);
                      return (
                        <div key={course.id}>
                          <StudentCourse
                            availableAdjustments={extraAdjustments}
                            students={students}
                            course={course}
                            setStudentCourse={setStudentCourse}
                            expanded={isExpanded}
                            setSelected={({courseId, studentId, checked}) => {
                              if (checked) {
                                setSelected([...selected, {studentId, courseId}]);
                              } else {
                                setSelected(selected.filter(s => !(s.courseId === courseId && s.studentId === studentId)))
                              }
                            }}
                            selected={selected}
                            selectMode={selectMode}
                            setHighlight={updateHighlightDebounced}
                            onSave={refetch}
                          />
                        </div>
                      );
                    })
                    }
                  </div>
                </ScrollSyncPane>
              </div>
            </div>
          </div>
        </ScrollSync>
      </div>
      }
      {showLegend && (
        <Legend
          close={() => {
            store.set('seenLegend', true);
            setShowLegend(false);
          }}
          showSplash={() => {
            setShowSplash(true);
          }}
        />
      )}
      {showSplash &&
      <Splash url={formatMessage({...messages.splash_url})} onClose={() => {
        setShowSplash(false);
        store.set('seenSplash', true);
      }}/>}
    </div>
  );
};

export default EWS;